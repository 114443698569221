import React, { Component } from 'react'
import {
  NavLink
} from "react-router-dom";
import $ from 'jquery';

export default class Navigation extends Component {
  remove = () => document.body.classList.remove('menu-open-mobile');
  render() {
    return (
      <div>
      <nav
        className="main-navigation"
        mobile-menu="767"
        animation-speed="300"
        scroll-bg="#c3c3c3"
        scroll-color="#a1a1a1"
        scroll-width="5"
      >
        <ul>
          <li  className="has-menu">
            <a data-toggle="collapse" id="navMenuBillingtoggle" className="" href="#billingMultiCollapse" title="Billing" >
              <img className="icon" src="images/billing.svg" alt="" />
              Billing
              <i className="arrow-icon"></i>
            </a>
            <ul className="collapse multi-collapse show" id="billingMultiCollapse">
              <li>
                <NavLink id="navLinkPaymentStatus" to="/PaymentStatus" title="Payment Status" activeClassName="active" onClick={this.remove}>
                  Payment Status
                </NavLink>
              </li>
              <li>
                <NavLink id="navLinkSubmitInvoice" to="/SubmitInvoice" title="Submit an Invoice" activeClassName="active" onClick={this.remove}>
                  Submit an Invoice
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/NewAppointment" title="Submit a new Appointment" activeClassName="active" onClick={this.remove}>
              <img className="icon" src="images/documentation.svg" alt="" />
              New Appointments
            </NavLink>
          </li>
          <li>
            <NavLink to="/MyProfile" title="My Profile" activeClassName="active" onClick={this.remove}>
              <img className="icon" src="images/my-profile.svg" alt="" />
              My Profile
            </NavLink>
          </li>
          <li>
            <NavLink to="/Feedback" title="Feedback" activeClassName="active" onClick={this.remove}>
              <img className="icon" src="images/feedback.svg" alt="" />
              Feedback
            </NavLink>
          </li>
          <li>
            <NavLink to="/FAQs" title="FAQs" activeClassName="active" onClick={this.remove}>
              <img className="icon" src="images/help.svg" alt="Faq" />
              FAQs
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="menu-overlay" onClick={this.remove}></div>
      </div>
    );
  }
}
