import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import Header from './components/Header/Header'
import Navigation from './components/Navigation/Navigation'

import Login from './components/Login/Login'
import SignUp from './components/SignUp/SignUp'
import PaymentStatus from './components/PaymentStatus/PaymentStatus'
import SubmitInvoice from './components/SubmitInvoice/SubmitInvoice'
import MyProfile from './components/MyProfile/MyProfile';
import { ValidateTokenAction } from './_redux/actions/sessionActions'
import FeedbackTab from './components/Feedback/Feedback';
import NewAppointment from './components/NewAppointment/NewAppointment';
import FAQs from './components/FAQs/FAQs';
import SelectProvider from './components/SelectProvider/SelectProvider'

class App extends Component {
  componentDidMount() {
    if (this.props.IsLoggedIn == "1") {
      const TokenData = { "Token": !!this.props.Token ? this.props.Token : "",UserAgent:window.navigator.userAgent };
      this.props.ValidateTokenAction(TokenData);
    }
    this.HideShowSelectProvider();
  }
  componentDidUpdate(prevProps) {
    if (parseInt(this.props.IsLoggedIn) !== parseInt(prevProps.IsLoggedIn) || parseInt(this.props.IsSuperAdmin) !== parseInt(prevProps.IsSuperAdmin) || parseInt(this.props.ProviderID) !== parseInt(prevProps.ProviderID)) {
      this.HideShowSelectProvider();
    }
  }
  HideShowSelectProvider(isDisplay)
  {
    isDisplay = isDisplay == true ? isDisplay : this.props.IsLoggedIn == "1" && parseInt(this.props.IsSuperAdmin) === 1 && parseInt(this.props.ProviderID) <= 0;
    if(isDisplay) {
      window.$('#selectProvider').modal("show");
    }
    else {
      window.$('#selectProvider').modal("hide");
    }
  }
  render() {
    return (
      <div className="wrapper">
        {/* <Loader /> */}
        <Router>
          {this.props.IsLoggedIn == "1" && <Header toggleLoginAs={this.HideShowSelectProvider} />}
          {this.props.IsLoggedIn == "1" && <Navigation />}
          <div className="modal show" id="selectProvider" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            {this.props.IsLoggedIn == "1" && parseInt(this.props.IsSuperAdmin) === 1 && 
                <SelectProvider></SelectProvider>
            }
          </div>
          {(this.props.IsLoggedIn != "1" || parseInt(this.props.IsSuperAdmin) !== 1 || parseInt(this.props.ProviderID) > 0) &&
          <Switch>
            <Route exact path="/">
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}
              <Link to="/Login">Go to Login page</Link>
            </Route>
            <Route exact path="/Login" component={Login}>
            </Route>
            <Route path="/SignUp/:id" component={SignUp}></Route>
            <Route exact path="/PaymentStatus">
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}
              <PaymentStatus />
            </Route>
            <Route exact path="/SubmitInvoice">
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}
              <SubmitInvoice />
            </Route>
            <Route exact path="/NewAppointment">
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}
              <NewAppointment />
            </Route>
            <Route exact path="/MyProfile">
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}
              <MyProfile />
            </Route>
            <Route exact path="/FeedBack">	
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}	
              <FeedbackTab />	
            </Route>
            <Route exact path="/FAQs">	
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}	
              <FAQs />	
            </Route>
            <Route>
              {this.props.IsLoggedIn != "1" && <Redirect to="/Login" />}
            </Route>
          </Switch>
          }
        </Router>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  IsLoggedIn: state.login.IsLoggedIn,
  Token: state.login.Token,
  IsSuperAdmin: state.login.IsSuperAdmin,
  ProviderID: state.login.ProviderID
})
export default connect(mapStateToProps, { ValidateTokenAction })(App)