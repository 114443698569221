import React from "react";

class InlineLoader extends React.Component {
    render() {
        return (
            <div className="inlineLoader">
                <img src='/images/loading.svg'></img>
            </div>
        );
    }
}

export default InlineLoader;
