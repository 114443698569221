import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Config from '../../Config'
import InlineLoader from '../../utilities/InlineLoader'

export default class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ProviderID: -1,
            ProviderUserID: '',
            ProviderCompanyName: '',
            ValidationMsg: 'data-loading',
            Password: '',
            ConfirmPassword: '',
            FormErrors: {
                Password: '',
                ConfirmPassword: '',
            },
            PasswordValid: true,
            ConfirmPasswordValid: true,
            FormValid: false,
            ShowPassword: false,
            ShowConfirmPassword: false,
            showBtnLoader: false
        }
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.validateField = this.validateField.bind(this);
        this.validateRequiredFieldsOnSubmite = this.validateRequiredFieldsOnSubmite.bind(this);

        this.fireRegisterEvent = this.fireRegisterEvent.bind(this);

        this.showPassword = this.showPassword.bind(this);
        this.hidePassword = this.hidePassword.bind(this);
    }
    componentDidMount() {
        axios.post(Config.API_URL + '/SignUp/GetDataForSignUp', { EncryptedQString: this.props.match.params.id })
            .then(res => {
                this.setState({
                    ProviderCompanyName: res.data.providerCompanyName,
                    ProviderID: res.data.providerID,
                    ProviderUserID: res.data.providerUserID,
                    ValidationMsg: res.data.validationMsg
                }, () => this.forceUpdate());
            })
    }

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => this.validateField(name, value));
    }
    onBlur(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.validateField(name, value);
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.FormErrors;
        let PasswordValid = this.state.PasswordValid;
        let ConfirmPasswordValid = this.state.ConfirmPasswordValid;

        switch (fieldName) {
            case 'Password':
                if (!value || value.trim() == '') {
                    PasswordValid = false;
                    fieldValidationErrors.Password = '*Please enter your Password';
                }
                else {
                    let passPattern1 = value.match(/[a-z]/);
                    let passPattern2 = value.match(/[A-Z]/);
                    let passPattern3 = value.match(/[0-9]/);
                    let passPattern4 = value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
                    let allValid = true;
                    fieldValidationErrors.Password = ''
                    if (!value || value.trim() == '') {
                        allValid = false;
                        PasswordValid = false;
                        fieldValidationErrors.Password = '*Please enter your Password';
                    }
                    else {
                        if (!!passPattern1 ? passPattern1.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 lowercase<NL>';
                        }
                        if (!!passPattern2 ? passPattern2.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 uppercase<NL>';
                        }
                        if (!!passPattern3 ? passPattern3.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 number<NL>';
                        }
                        if (!!passPattern4 ? passPattern4.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 special character<NL>';
                        }
                        if (value.trim().length < 8) {
                            allValid = false;
                            PasswordValid = false
                            fieldValidationErrors.Password += '*Password must be a minimum of 8 characters<NL>';
                        }
                        if (allValid) {
                            PasswordValid = true;
                            fieldValidationErrors.Password = '';
                        }
                    }
                }
                if (this.state.ConfirmPasswordValid && this.state.ConfirmPassword.length == 0)
                    break;
                else
                    value = this.state.ConfirmPassword.trim();
            case 'ConfirmPassword':
                if (!value || value.trim() == '') {
                    ConfirmPasswordValid = false;
                    fieldValidationErrors.ConfirmPassword = '*Please enter Confirm Password';
                }
                else {
                    ConfirmPasswordValid = (this.state.Password.trim() == value.trim());
                    fieldValidationErrors.ConfirmPassword = ConfirmPasswordValid ? '' : '*Password and Confirm Password do not match';
                }
                break;
            default:
                break;
        }
        this.setState({
            FormErrors: fieldValidationErrors,
            PasswordValid: PasswordValid,
            ConfirmPasswordValid: ConfirmPasswordValid
        });
    }
    validateRequiredFieldsOnSubmite(e) {
        e.preventDefault();
        let fieldValidationErrors = this.state.FormErrors;
        let PasswordValid = this.state.PasswordValid;
        let ConfirmPasswordValid = this.state.ConfirmPasswordValid;

        let passPattern1 = this.state.Password.match(/[a-z]/);
        let passPattern2 = this.state.Password.match(/[A-Z]/);
        let passPattern3 = this.state.Password.match(/[0-9]/);
        let passPattern4 = this.state.Password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
        let allValid = true;
        fieldValidationErrors.Password = ''
        if (!this.state.Password || this.state.Password.trim() == '') {
            allValid = false;
            PasswordValid = false;
            fieldValidationErrors.Password = '*Please enter your Password';
        }
        else {
            if (!!passPattern1 ? passPattern1.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 lowercase<NL>';
            }
            if (!!passPattern2 ? passPattern2.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 uppercase<NL>';
            }
            if (!!passPattern3 ? passPattern3.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 number<NL>';
            }
            if (!!passPattern4 ? passPattern4.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 special character<NL>';
            }
            if (this.state.Password.trim().length < 8) {
                allValid = false;
                PasswordValid = false
                fieldValidationErrors.Password += '*Password must be a minimum of 8 characters<NL>';
            }
            if (allValid) {
                PasswordValid = true;
                fieldValidationErrors.Password = '';
            }
        }
        if (!this.state.ConfirmPassword || this.state.ConfirmPassword.trim() == '') {
            ConfirmPasswordValid = false;
            fieldValidationErrors.ConfirmPassword = '*Please enter Confirm Password';
        }
        if (ConfirmPasswordValid) {
            ConfirmPasswordValid = (this.state.Password.trim() == this.state.ConfirmPassword.trim());
            fieldValidationErrors.ConfirmPassword = ConfirmPasswordValid ? '' : '*Password and Confirm Password do not match';
        }
        this.setState({
            FormErrors: fieldValidationErrors,
            PasswordValid: PasswordValid,
            ConfirmPasswordValid: ConfirmPasswordValid
        }, this.validateForm);
    }
    validateForm() {
        this.setState({
            FormValid: this.state.PasswordValid
                && this.state.ConfirmPasswordValid
        }, this.fireRegisterEvent);
    }
    fireRegisterEvent() {
        if (this.state.FormValid) {
            axios.post(Config.API_URL + '/SignUp/CreateProviderAccount', {
                ProviderID: this.state.ProviderID,
                ProviderUserID: this.state.ProviderUserID,
                Password: this.state.Password.trim()
            })
                .then(res => {
                    if (parseInt(res.data) > 0) {
                        this.setState({
                            ValidationMsg: 'account-created'
                        })
                    }
                    else {
                        this.componentDidMount();
                    }
                })
        }
    }
    showPassword(type) {
        this.setState({ [type]: true });
    }
    hidePassword(type) {
        this.setState({ [type]: false });
    }
    gotoLogIn = () => {
        return (<p className="sign-up p-0">Go to <Link to="/Login" title="Log In"> Log In</Link></p>);
    }
    render() {
        return (
            <div>
                <div className="wrapper login-page">
                    <div className="login-panel">
                        <div className="login-block with-signUp">
                            <img src="/images/logo.png" alt="" />
                            <div className="card">
                                {this.state.ValidationMsg == 'data-loading' && (<div><InlineLoader></InlineLoader> {this.gotoLogIn()}</div>)}
                                {this.state.ValidationMsg == '' &&
                                    <div>
                                        <h2 className="strong">Welcome, {this.state.ProviderCompanyName}</h2>
                                        <form onSubmit={this.validateRequiredFieldsOnSubmite}>
                                            <div className="row">
                                                <div className="col-md-8 offset-md-2 text-left">
                                                    <div className="form-group">
                                                        <label>Provider ID</label>
                                                        <input type="text" className="form-control" readOnly value={this.state.ProviderID} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>User ID</label>
                                                        <input type="text" className="form-control" readOnly value={this.state.ProviderUserID} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <div className="signUp-form-group">
                                                            <input
                                                                type={!this.state.ShowPassword ? "password" : "text"}
                                                                className={!this.state.PasswordValid ? "form-control inValidaData" : "form-control"}
                                                                placeholder="Password"
                                                                name="Password"
                                                                value={this.state.Password}
                                                                onChange={this.onChange}
                                                                onBlur={this.onBlur}
                                                                title="Please enter your Password"
                                                                tabIndex={1}
                                                                autoFocus
                                                            />
                                                            <span
                                                                onTouchStart={() => this.showPassword('ShowPassword')}
                                                                onTouchEnd={() => this.hidePassword('ShowPassword')}
                                                                onMouseDown={() => this.showPassword('ShowPassword')}
                                                                onMouseUp={() => this.hidePassword('ShowPassword')}
                                                                onMouseLeave={() => this.hidePassword('ShowPassword')}
                                                                title="Show Password"
                                                                className={!this.state.ShowPassword ? "password-show show" : "password-show show active"}></span>
                                                        </div>
                                                        {!this.state.PasswordValid && <div className="errorMsg">{this.state.FormErrors.Password.split('<NL>').map((item, key) => { return (<div key={key}>{item}</div>) })}</div>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <div className="signUp-form-group">
                                                            <input
                                                                type={!this.state.ShowConfirmPassword ? "password" : "text"}
                                                                className={!this.state.ConfirmPasswordValid ? "form-control inValidaData" : "form-control"}
                                                                placeholder="Confirm Password"
                                                                name="ConfirmPassword"
                                                                value={this.state.ConfirmPassword}
                                                                onChange={this.onChange}
                                                                onBlur={this.onBlur}
                                                                title="Please enter Confirm Password"
                                                                tabIndex={2}
                                                            />
                                                            <span
                                                                onTouchStart={() => this.showPassword('ShowConfirmPassword')}
                                                                onTouchEnd={() => this.hidePassword('ShowConfirmPassword')}
                                                                onMouseDown={() => this.showPassword('ShowConfirmPassword')}
                                                                onMouseUp={() => this.hidePassword('ShowConfirmPassword')}
                                                                onMouseLeave={() => this.hidePassword('ShowConfirmPassword')}
                                                                title="Show Password" className={!this.state.ShowConfirmPassword ? "password-show show" : "password-show show active"}>
                                                            </span>
                                                        </div>
                                                        {!this.state.ConfirmPasswordValid && <div className="errorMsg">{this.state.FormErrors.ConfirmPassword.split('<NL>').map((item, key) => { return (<div key={key}>{item}</div>) })}</div>}
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-block" title="Sign Up"><span>Create Account</span></button>
                                                </div>
                                            </div>
                                        </form>
                                        {this.gotoLogIn()}
                                    </div>
                                }
                                {this.state.ValidationMsg == 'aleady-active' &&
                                    <div>
                                        <h2>
                                            It looks like your account is <b className="strong"> already activated.</b>
                                        </h2>
                                        <h3>
                                            Please proceed by <Link to="/Login" title="Log In" style={{ color: 'blue' }}>logging in to your account</Link>.
                                        </h3>
                                    </div>
                                }{
                                    (this.state.ValidationMsg == 'account-created') &&
                                    <div>
                                        <h2>Your account has been created successfully</h2>
                                        <h3>You can now log in with <b className="strong">your User ID ({this.state.ProviderUserID})</b></h3>
                                        {this.gotoLogIn()}
                                    </div>
                                }
                                {(this.state.ValidationMsg == 'prime-inactive' || this.state.ValidationMsg == 'data-mismatch') &&
                                    <div className="mt-n3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="80" className="sad-emoji" height="80" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                        <h2>
                                            We are unable to process at this time </h2>
                                        <h2> please<b className="strong"> call us at <a href="tel:+18669417878" title="Click to call" className="primary-link">866-941-7878</a> Option 5 </b>
                                        </h2>
                                    </div>
                                }
                                {!['data-loading', 'aleady-active', 'account-created', 'prime-inactive','data-mismatch',''].includes(this.state.ValidationMsg) &&
                                    <div className="mt-n3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="80" className="sad-emoji" height="80" viewBox="0 0 24 24" fill="none" stroke="#7c7c7c" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                        <h2>
                                            We are unable to process at this time </h2>
                                        <h2> please<b className="strong"> call us at <a href="tel:+18669417878" title="Click to call" className="primary-link">866-941-7878</a> Option 5 </b>
                                        </h2>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}