import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../Config'
import InlineLoader from '../../utilities/InlineLoader'

export default class SubmitInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            InvoiceData: [],
            currentPageData: [],
            PageIndex: 1,
            PageSize: 10,
            currentSortCol: 'dateOfService',
            sortOrder: 'DESC',
            InvoiceDataLoaded: false
        }


        this.openSubmitInvoicePopup = this.openSubmitInvoicePopup.bind(this);
        this.setPageSize = this.setPageSize.bind(this);
        this.setPageIndex = this.setPageIndex.bind(this);
        this.sortData = this.sortData.bind(this);
    }
    componentDidMount() {
        axios.get(Config.API_URL + '/SubmitInvoice/GetDataForSubmitInvoice')
            .then(res => {
                this.setState({
                    InvoiceData: res.data,
                    InvoiceDataLoaded: true
                }, () => this.setPageData(this.state.InvoiceData))
            })
    }
    openSubmitInvoicePopup(serviceType) {
        if (serviceType == "TR")
            window.open(Config.SubmitInvoiceTRLink, "_blank");
        else
            window.open(Config.SubmitInvoiceTLLink, "_blank");
    }
    setPageSize(e) {
        let maxPageIndex = Math.ceil(this.state.InvoiceData.length / e.target.value);
        this.setState({
            PageSize: e.target.value,
            PageIndex: this.state.PageIndex > maxPageIndex ? maxPageIndex : this.state.PageIndex
        }, () => this.setPageData(this.state.InvoiceData));
    }
    setPageIndex(e) {
        this.setState({
            PageIndex: parseInt(e.target.name)
        }, () => this.setPageData(this.state.InvoiceData));
    }
    setPageData(data) {
        this.setState({
            currentPageData: data.slice(this.state.PageSize * this.state.PageIndex - this.state.PageSize, this.state.PageSize * this.state.PageIndex)
        })
    }
    sortData(Index, DType) {
        let sortedData = [];

        sortedData = [].concat(this.state.InvoiceData)
            .sort((a, b) => sortEquation(a, b, this.state.sortOrder))
            .map((item, i) => { return item; }
            );
        let newOrder = this.state.sortOrder == 'DESC' ? "ASC" : 'DESC';
        this.setState({
            InvoiceData: sortedData,
            sortOrder: newOrder,
            currentSortCol: Index
        }, () => this.setPageData(this.state.InvoiceData))

        function sortEquation(a, b, sortOrder) {
            if (DType == 'number') {
                return sortOrder == 'DESC' ? parseInt(a[Index]) - parseInt(b[Index]) : parseInt(b[Index]) - parseInt(a[Index]);
            }
            else if (DType == 'date') {
                return sortOrder == 'DESC' ? dateToInt(a[Index]) - dateToInt(b[Index]) : dateToInt(b[Index]) - dateToInt(a[Index]);
            }
            else if (DType == 'string') {
                return (sortOrder == 'DESC' ? a[Index] > b[Index] : b[Index] > a[Index]) ? 1 : -1;
            }
        }
        function dateToInt(date) {
            let [m, d, y] = date.split('/');
            if(isNaN(parseInt(y + m + d))){
				return -1;
			}
            return parseInt(y + m + d);
        }
    }
    renderPagination() {
        let maxPageIndex = Math.ceil(this.state.InvoiceData.length / this.state.PageSize);
        let pageButtons = []
        let startIndex = 1;
        let lastIndex = 5;
        if (this.state.PageIndex > 3) {
            if (this.state.PageIndex + 2 <= maxPageIndex) {
                startIndex = this.state.PageIndex - 2;
                lastIndex = Math.min(this.state.PageIndex + 2, maxPageIndex)
            }
            else {
                startIndex = Math.max(maxPageIndex - 4, 1);
                lastIndex = maxPageIndex;
            }
        }
        for (startIndex; startIndex <= Math.min(lastIndex, maxPageIndex); startIndex++) {
            pageButtons.push(<li key={startIndex}><a onClick={this.setPageIndex} name={startIndex} className={startIndex == this.state.PageIndex ? "active" : ""}>{startIndex}</a></li>)
        }
        return (
            <div>
                <div className="grid-bottom row">
                    <div className="col-lg">
                        <div className="show-record">
                            <select onChange={this.setPageSize} name="PageSize" value={this.state.PageSize} className="custom-select" style={{ height: "38px" }}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span className="form-label">Records per page</span>
                        </div>
                    </div>
                    <div className="col-lg">

                        <div className="paginetion float-right">
                            {maxPageIndex != 1 && <ul style={{ cursor: "pointer" }}>
                                {maxPageIndex > 5 && (this.state.PageIndex != 1 ? <li><a onClick={this.setPageIndex} name={1} className="prevar first"></a></li> : <li><a style={{ cursor: "default" }} className="prevar first opa-4"></a></li>)}
                                {maxPageIndex > 5 && (this.state.PageIndex != 1 ? <li><a onClick={this.setPageIndex} name={this.state.PageIndex - 1} className="prevar"></a></li> : <li><a style={{ cursor: "default" }} className="prevar opa-4"></a></li>)}
                                {pageButtons.map(i => { return i; })}
                                {maxPageIndex > 5 && (this.state.PageIndex != maxPageIndex ? <li><a onClick={this.setPageIndex} name={this.state.PageIndex + 1} className="nxtar"></a></li> : <li><a style={{ cursor: "default" }} className="nxtar opa-4"></a></li>)}
                                {maxPageIndex > 5 && (this.state.PageIndex != maxPageIndex ? <li><a onClick={this.setPageIndex} name={maxPageIndex} className="nxtar last"></a></li> : <li><a style={{ cursor: "default" }} className="nxtar last opa-4"></a></li>)}
                            </ul>}

                            {/* <div style={{ textAlign: "right" }}>{this.state.InvoiceData.length + ' records, in ' + maxPageIndex + ' page' + (maxPageIndex > 1 ? 's' : '')}</div> */}
                        </div>
                        <div className="float-right" style={{ margin: "11px 25px" }}>
                            {this.state.PageIndex * this.state.PageSize - this.state.PageSize + 1} - {Math.min(this.state.PageIndex * this.state.PageSize, this.state.InvoiceData.length)} of {this.state.InvoiceData.length}
                        </div>
                    </div>
                </div>

            </div>);
    }
    render() {
        return (
            <div>
                <main className="main-content">
                    <div className="container-fluid">
                        {Config.AlertMessage &&
							<div className="row">
								<div className="col-xl-12 col-lg-12">
									<div className="alert alert-warning">
										<strong>***{Config.AlertMessage}</strong>
									</div>
								</div>
							</div>}
                        <div className="row">
                            <div className="col-xl-7 col-lg-6 pb-3">
                                <div className="title">
                                    <h1>Submit an Invoice</h1>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="alert alert-danger">
                                    <strong>***Please allow up to 72 hours to reflect any recently submitted invoices</strong>
                                    <p className="pt-2 pb-0"><strong>***Payment Information prior to Date of Service 01/21/2020 will not be visible in this portal</strong></p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Grid Table =====================================--> */}
                        <div className="table-responsive grid-table">

                            <table className="table table-hover table-border">
                                <thead>
                                    <tr>
                                        <th onClick={() => this.sortData('appointmentNumber', 'number')} style={{ cursor: "pointer" }}>
                                            PO#
                                            {this.state.currentSortCol == "appointmentNumber" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                            {this.state.currentSortCol == "appointmentNumber" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                        </th>
                                        <th onClick={() => this.sortData('dateOfService', 'date')} style={{ cursor: "pointer" }}>
                                            Date of service
                                            {this.state.currentSortCol == "dateOfService" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                            {this.state.currentSortCol == "dateOfService" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                        </th>
                                        <th onClick={() => this.sortData('serviceProvided', 'string')} style={{ cursor: "pointer" }}>
                                            Service provided
                                            {this.state.currentSortCol == "serviceProvided" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                            {this.state.currentSortCol == "serviceProvided" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                        </th>
                                        <th onClick={() => this.sortData('injuredWorker', 'string')} style={{ cursor: "pointer" }}>
                                            Injured worker
                                            {this.state.currentSortCol == "injuredWorker" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
                                            {this.state.currentSortCol == "injuredWorker" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
                                        </th>
                                        {/* <th align="center" className="text-center">Status</th> */}
                                        <th align="center" className="text-center">Submit Invoice</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.currentPageData.length > 0 && this.state.currentPageData.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{item.appointmentNumber}</td>
                                                <td>{item.dateOfService}</td>
                                                <td>{item.serviceProvided}</td>
                                                <td>{item.injuredWorker}</td>
                                                <td align="center" width="10">
                                                    {item.canSubmitInvoice==1&&<button type="button" name={item.serviceType} onClick={()=> {this.openSubmitInvoicePopup(item.serviceType);}} className="btn btn-primary btn-md"><span>Submit</span></button>}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {this.state.InvoiceDataLoaded && this.state.currentPageData.length == 0 && <div className="pd-10-12"><b></b>No records found...</div>}
                            {!this.state.InvoiceDataLoaded && <InlineLoader></InlineLoader>}
                        </div>
                        {this.state.currentPageData.length != 0 && this.renderPagination()}
                    </div>
                </main>
            </div>
        )
    }
}
