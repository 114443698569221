import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LogOutAction } from '../../_redux/actions/sessionActions'
import { Redirect } from 'react-router-dom'
import $ from 'jquery';
import {
    Link
  } from "react-router-dom";

class Header extends Component {
    componentDidMount(){
        var $nav = $(".main-navigation");
        var $mobileMenu = $nav.attr("mobile-menu");
        $(".nav-icon, .menu-overlay").click(function(){
            if($(window).innerWidth() > $mobileMenu)
                $("body").toggleClass('menu-open');
            else
                $("body").toggleClass('menu-open-mobile');
        });
    }
    constructor(props) {
        super(props);
        this.handleLoginAsClick = this.handleLoginAsClick.bind(this);
    }
    handleLoginAsClick() {
        this.props.toggleLoginAs(true);
    }
    fireLogOut() {
        const LogOutData = { "Token": !!this.props.Token ? this.props.Token : "" };
        this.props.LogOutAction(LogOutData);
    }
    render() {
        if (this.props.IsLoggedIn != "1") {
            return <Redirect to="/Login" />
        }
        return (
            <header className="header">
                <div className="container-fluid">
                    <div className="row align-items-center no-gutters">
                        <div className="col">
                            <Link to="/MyProfile" title="ProCare" className="logo">
                                <img src="images/logo.png" alt="" />
                            </Link>
                        </div>
                        <div className="col-auto">
                            {parseInt(this.props.IsSuperAdmin) === 1 && <button type="button" className="btn btn-primary mr-5" onClick={this.handleLoginAsClick}><span>Login As</span></button>}
                            <Link to="/MyProfile" title={'User Id - '+this.props.UserName+ '\n' + 'Provider Id - '+this.props.ProviderID} className="profile-block">
                                <img className="profile-img" src="images/profile.svg" alt="User" />
                                <span className="name text-truncate">{this.props.UserName}</span>
                            </Link>
                            <span title="Logout" className="logout" onClick={this.fireLogOut.bind(this)}><img src="images/logout.svg" alt="" /></span>
                            <button className="nav-icon">
                                <span className="one"></span>
                                <span className="two"></span>
                                <span className="three"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
const mapStateToProps = state => ({
    Token: state.login.Token,
    IsLoggedIn: state.login.IsLoggedIn,
    UserName: state.login.UserName,
    ProviderID: state.login.ProviderID,
    IsSuperAdmin: state.login.IsSuperAdmin
})
export default connect(mapStateToProps, { LogOutAction })(Header)