import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../Config'
import iZitoast from 'izitoast'

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        const localUserName = localStorage.getItem('UserName');
        const localRememberMe = localStorage.getItem('RememberMe');

        this.state = {
            UserName: !!localUserName && (localRememberMe ? localRememberMe == 'true' : false) ? localUserName : '',
            ProviderID: '',
            FormErrors: {
                UserName: '',
                ProviderID: ''
            },
            UserNameValid: true,
            ProviderIDValid: true,
            FormValid: false,
            showForgotPasswordLoader: false
        }

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.validateField = this.validateField.bind(this);
        this.validateRequiredFieldsOnSubmite = this.validateRequiredFieldsOnSubmite.bind(this);
        this.fireForgotPwd = this.fireForgotPwd.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }
    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => this.validateField(name, value));
    }
    onBlur(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.validateField(name, value);
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.FormErrors;
        let UserNameValid = this.state.UserNameValid;
        let ProviderIDValid = this.state.ProviderIDValid;

        switch (fieldName) {
            case 'UserName':
                if (!value || value.trim() == '') {
                    UserNameValid = false;
                    fieldValidationErrors.UserName = '*Please enter User ID';
                }
                else {
                    let pattern = this.state.UserName.match(/^(([\w.%+-]+)@([\w-]+\.)+([\w]{2,}))$/i);
                    UserNameValid = !!pattern ? pattern.length > 0 : false;
                    fieldValidationErrors.UserName = UserNameValid ? '' : '*Please enter valid User ID';
                }
                break;
            case 'ProviderID':
                if (!value || value.trim() == '') {
                    ProviderIDValid = false;
                    fieldValidationErrors.ProviderID = '*Please enter Provider ID';
                }
                else {
                    let pattern = this.state.ProviderID.match(/^([0-9]*)$/i);
                    ProviderIDValid = !!pattern ? pattern.length > 0 : false;
                    fieldValidationErrors.ProviderID = ProviderIDValid ? '' : '*Please enter valid Provider ID';
                }
                break;
            default:
                break;
        }
        this.setState({
            FormErrors: fieldValidationErrors,
            UserNameValid: UserNameValid,
            ProviderIDValid: ProviderIDValid
        });
    }
    validateRequiredFieldsOnSubmite(e) {
        e.preventDefault();
        let fieldValidationErrors = this.state.FormErrors;
        let UserNameValid = this.state.UserNameValid;
        let ProviderIDValid = this.state.ProviderIDValid;

        if (!this.state.UserName || this.state.UserName.trim() == '') {
            UserNameValid = false;
            fieldValidationErrors.UserName = UserNameValid ? '' : '*Please enter User ID';
        }
        if (UserNameValid) {
            let pattern = this.state.UserName.match(/^(([\w.%+-]+)@([\w-]+\.)+([\w]{2,}))$/i);
            UserNameValid = !!pattern ? pattern.length > 0 : false;
            fieldValidationErrors.UserName = UserNameValid ? '' : '*Please enter valid User ID';
        }
        if (!this.state.ProviderID || this.state.ProviderID.trim() == '') {
            ProviderIDValid = false;
            fieldValidationErrors.ProviderID = ProviderIDValid ? '' : '*Please enter Provider ID';
        }
        if (ProviderIDValid) {
            let pattern = this.state.ProviderID.match(/^([0-9]*)$/i);
            ProviderIDValid = !!pattern ? pattern.length > 0 : false;
            fieldValidationErrors.ProviderID = ProviderIDValid ? '' : '*Please enter valid Provider ID';
        }

        this.setState({
            FormErrors: fieldValidationErrors,
            UserNameValid: UserNameValid,
            ProviderIDValid: ProviderIDValid
        }, this.validateForm);
    }
    validateForm() {
        this.setState({
            FormValid: this.state.UserNameValid
                && this.state.ProviderIDValid
        }, this.fireForgotPwd);
    }
    fireForgotPwd() {
        if (this.state.FormValid) {
            const postData = {
                "LoginID": this.state.UserName,
                "ProviderID": parseInt(this.state.ProviderID)
            }
            this.setState({ showForgotPasswordLoader: true }, () => {
                axios.post(Config.API_URL + '/Session/ForgotPassword', postData)
                    .then(res => {
                        if (res.data == 1) {
                            iZitoast.destroy();
                            iZitoast.success({ message: "Your password has been reset successfully!<br/>Your new password has been sent to your email address.", timeout: 10000 });
                            this.modelCloseBtn.click();
                            this.setState({
                                UserName: '',
                                ProviderID: ''
                            });
                        }
                        else if (res.data == -3) {
                            iZitoast.destroy();
                            iZitoast.error({ message: "We are unable to process your request at this time please call us at 866-941-7878 Option 5 ", timeout: 7000 });
                        }
                        else if (res.data == -1) {
                            iZitoast.destroy();
                            iZitoast.error({ message: "you have entered invalid Provider ID", timeout: 7000 });
                        }
                        else if (res.data == -2) {
                            iZitoast.destroy();
                            iZitoast.error({ message: "you have entered invalid User ID", timeout: 7000 });
                        }
                        else {
                            iZitoast.destroy();
                            iZitoast.error({ message: "something went wrong. please refresh the page and try again", timeout: 7000 });
                        }
                    }).catch(err => {
                        console.log(err);
                        if (err.response.statusText == "Conflict") {
                            alert('something went wrong. please refresh the page and try again');
                        }
                        else {
                            alert(err.message + '(' + err.response.statusText + ')');
                        }
                    }).then(() => {
                        this.setState({ showForgotPasswordLoader: false });
                    });
            });
        }
    }
    render() {
        return (
            <div>
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title">Forgot Password</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src="images/close.svg" alt="" />
                            </button>
                        </div>
                        <form name="ForgotPassword" onSubmit={this.validateRequiredFieldsOnSubmite}>
                            <div className="modal-body pb-0">

                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={!this.state.ProviderIDValid ? "form-control inValidaData" : "form-control"}
                                        placeholder="Provider ID"
                                        name="ProviderID"
                                        value={this.state.ProviderID}
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        title="Please enter your Provider ID"
                                        autoFocus
                                        tabIndex={7}
                                    />
                                    {!this.state.ProviderIDValid && <div className="errorMsg">{this.state.FormErrors.ProviderID}</div>}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={!this.state.UserNameValid ? "form-control inValidaData" : "form-control"}
                                        placeholder="User ID"
                                        name="UserName"
                                        value={this.state.UserName}
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        title="Please enter your User ID"
                                        tabIndex={8}
                                    />
                                    {!this.state.UserNameValid && <div className="errorMsg">{this.state.FormErrors.UserName}</div>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button tabIndex={9} type="submit" className={this.state.showForgotPasswordLoader ? "btn btn-primary loading" : "btn btn-primary"} title="Forgot Password"><span>Forgot Password</span></button>
                                <button type="button" tabIndex={10}  className="btn btn-secondary" data-dismiss="modal" ref={button => this.modelCloseBtn = button}><span>Cancel</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}