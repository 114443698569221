import { LOGIN_ACTIONS, LOGOUT_ACTIONS, LOGINAS_ACTIONS } from './types';
import axios from 'axios'
import Config from '../../Config'
import iZitoast from 'izitoast'

export const LogInAction = (postData, callBack) => dispatch => {
    axios.post(Config.API_URL + '/Session/LogIn', postData)
        .then(res => {
            callBack();
            if (res.data.logInStatus == 1) {
                var oldToast = document.querySelector('.LogInError');
                if (oldToast != null)
                    iZitoast.hide({ transitionOut: 'fadeOutUp' }, oldToast);
                if(res.data.showPassWarning>0){
                    let msg = 'Your <span class="strong">Password</span> will be <span class="strong">Expired</span> in <span class="strong">'+res.data.showPassWarning+' day'+(res.data.showPassWarning==1?'':'s')+'</span>, please change your password from \'My Profile\' section';
                    iZitoast.warning({ message: msg, timeout: 0 });
                }
                if(res.data.isDefaultPassword){
                    let msg = 'Please change your default system generated password';
                    iZitoast.warning({ message: msg, timeout: 0 });
                }
                dispatch({
                    type: LOGIN_ACTIONS,
                    payload: {
                        IsLoggedIn: res.data.logInStatus,
                        Token: res.data.token,
                        UserName: res.data.userName,
                        ProviderID: res.data.providerID,
                        ProviderNetTerms: res.data.providerNetTerms,
                        IsSuperAdmin: res.data.isSuperAdmin
                    }
                })
            }
            else {
                iZitoast.destroy();
                iZitoast.error({ class:'LogInError' ,message: res.data.errorMsg, timeout: 7000 });
            }
        });
}
export const LogOutAction = (postData) => dispatch => {
    iZitoast.destroy();
    axios.post(Config.API_URL + '/Session/LogOut', postData)
        .then(res => {
            if (res.status == 200) {
                dispatch({
                    type: LOGOUT_ACTIONS,
                    payload: {}
                })
            }
            else {
                console.log(res);
                alert('something went wrong. please refresh the page and try again');
            }
        })
        .catch(
            err => {
                console.log(err);
                if (err.response.statusText == "Conflict") {
                    alert('something went wrong. please refresh the page and try again');
                }
                else if (err.response.statusText == "Unauthorized") {
                    alert('May be you are already logged out, please refresh and try again');
                }
                else {
                    alert(err.message + '(' + err.response.statusText + ')');
                }
            })
}

export const ValidateTokenAction = (postData) => dispatch => {
    axios.post(Config.API_URL + '/Session/CheckAccessToken', postData)
        .then(res => {
            if (res.status == 200) {

            }
            else {
                console.log(res);
                alert('something went wrong. please refresh the page and try again');
            }
        })
        .catch(
            err => {
                dispatch({
                    type: LOGOUT_ACTIONS,
                    payload: {}
                })
            })
}

export const GetUpdateProviderForAdmin = (postData, callBack) => dispatch => {
    axios.post(Config.API_URL + '/Admin/GetUpdateProviderForAdmin', postData)
        .then(res => {
            if(postData.Flag == false)
            {
                callBack(res.data, postData.Flag);
            }
            else
            {
                dispatch({
                    type: LOGINAS_ACTIONS,
                    payload: { ProviderID: postData.ProviderID}
                })
                callBack(res.data, postData.Flag);
            }
        })
}