import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../Config'
// import { connect } from 'react-redux'
import iZitoast from 'izitoast'
import ChangePassword from  '../ChangePassword/ChangePassword'
import store from '../../_redux/store';
import $ from 'jquery';
import { connect } from 'react-redux'

class MyProfile extends Component {
	componentWillUnmount(){
		window.$('.modal').modal("hide");
	}
	
	getData() {
		// const state = store.getState();
		const Token = this.props.Token;
		const ProviderID = parseInt(this.props.ProviderID);
		const IsSuperAdmin = parseInt(this.props.IsSuperAdmin);
		const params = {
			accessToken: Token
		};
		
		if(IsSuperAdmin === 1 && ProviderID <= 0)
		{
			return false;
		}
		axios.get(Config.API_URL + '/ProfileDetails/GetDetails', { params }
		)
			.then((response) => {
				this.setState(
					{
						DispatchDetails: {
								ContactName: response.data.Table.length > 0 ? response.data.Table[0].Contact : '',
								Email: response.data.Table.length > 0 ? response.data.Table[0].Email1 : '',
								AltEmail: response.data.Table.length > 0 ? response.data.Table[0].Email2 : '',
								Phone: response.data.Table.length > 0 ? response.data.Table[0].Phone1 : '',
								AltPhone: response.data.Table.length > 0 ? response.data.Table[0].Phone2 : '',
								Address: response.data.Table.length > 0 ? response.data.Table[0].Address : ''
						},
						BillingDetails: {
								ContactName: response.data.Table1.length > 0 ? response.data.Table1[0].Contact : '',
								Email: response.data.Table1.length > 0 ? response.data.Table1[0].Email1 : '',
								AltEmail: response.data.Table1.length > 0 ? response.data.Table1[0].Email2 : '',
								Phone: response.data.Table1.length > 0 ? response.data.Table1[0].Phone1 : '',
								AltPhone: response.data.Table1.length > 0 ? response.data.Table1[0].Phone2 : '',
								Address: response.data.Table1.length > 0 ? response.data.Table1[0].Address : ''
						}
					});
			});
	}

	constructor(props) {
		super(props);
		this.state = {
			DispatchDetails: {
				ContactName: "",
				Email: "",
				AltEmail: "",
				Phone: "",
				AltPhone: "",
				Address: "",
			},
			BillingDetails: {
				ContactName: "",
				Email: "",
				AltEmail: "",
				Phone: "",
				AltPhone: "",
				Address: "",
			},
			Type : '',
			Subject : '',
			Message : '',
			Errors : {Subject : '', Message : ''},
			IsRequestValid : true,
			IsModalOpen : false
		}
		this.handleChange = this.handleChange.bind(this);
		this.validateField = this.validateField.bind(this);
		this.validateRequestOnSend = this.validateRequestOnSend.bind(this);
		this.onModalClose = this.onModalClose.bind(this);
		this.SetDispatchEmail = this.SetDispatchEmail.bind(this);
		this.getData();
	}

	ChangePhoneFormat(phoneNumber) {
		let value = "";
		if (phoneNumber !== null && phoneNumber.trim() !== "" && phoneNumber.trim() !== null) {
			if (phoneNumber.trim().length === 10) {
				value = "(" + phoneNumber.slice(0, 3) + ")" + phoneNumber.slice(3, 6) + "-" + phoneNumber.slice(6);
			}
			else {
				value = phoneNumber;
			}
			return value;
		}
	}

	handleChange(e) {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;

		this.setState({
			[fieldName] : fieldValue
		}, () => this.validateField(fieldName, fieldValue));
	}

	validateField(fieldName, value) {
		let IsRequestValid = true;
		let Errors = this.state.Errors;

		switch (fieldName) {
			case "Subject":
				if (!value || value.trim() === '') {
					IsRequestValid = false;
					Errors.Subject = "*Please enter Subject";

				}
				else {
					Errors.Subject = "";
				}
				break;
			case "Message":
				if (!value || value.trim() === '') {
					IsRequestValid = false;
					Errors.Message = "*Please enter message for update request";
				}
				else {
					Errors.Message = "";
				}
				break;
			default:
				Errors = {};
				break;
		}
		this.setState({
			IsRequestValid : IsRequestValid,
			Errors : Errors
		});
	}

	onModalClose() {
		this.setState({
			IsRequestValid : true,
			Subject : '',
			Message : '',
			Errors : {}
		});
	}
	
	validateRequestOnSend(e){
		e.preventDefault();
		let Subject = this.state.Subject;
		let Message = this.state.Message;
		let Errors = this.state.Errors;
		let IsRequestValid = this.state.IsRequestValid;

		if (!this.state.Subject || this.state.Subject.trim() == '') {
            IsRequestValid = false;
            Errors.Subject ='*Please enter Subject';
		}
		if (!this.state.Message || this.state.Message.trim() == '') {
            IsRequestValid = false;
            Errors.Message ='*Please enter message for update request';
		}
		this.setState({
            Subject: Subject,
            Message: Message,
            IsRequestValid : IsRequestValid
        }, ()=> this.SendRequest());
	}

	SendRequest() {
		if(this.state.IsRequestValid){
			const state = store.getState();
			const Token = state.login.Token;
			const UpdateRequestData = {
				"AccessToken": Token,
				"Subject": this.state.Subject,
				"Message": this.state.Message,
			};
			axios.post(Config.API_URL + '/ProfileDetails/SendUpdateRequest', UpdateRequestData)
			.then(res => {
				if (!res.data.responseMsg && res.data.responseMsg === '') {
					
					this.setState({
						Subject: "",
						Message: "",
						IsRequestValid: true
					}, () => {
						iZitoast.destroy();
						iZitoast.success({ message: "Your update request has been successfully sent.", timeout: 5000 });

						document.getElementById('btnCloseRequestModal').click();
					});
				}else{
					iZitoast.destroy();
					iZitoast.error({ message: res.data.responseMsg, timeout: 5000 });
				}
			});
		}
	}
	
	SetDispatchEmail(){
		this.setState({
			DispatchDetails : {
				...this.state.DispatchDetails,
				Email : this.state.DispatchDetails.Email,
			}
		});
	}
	
	render() {
		const {DispatchDetails, BillingDetails} = this.state;
		return (
			<div>
				<main className="main-content">
					<div className="container-fluid">
						<div className="title">
							<h1>My Profile</h1>
						</div>

						<div className="row">
							<div className="col-lg">
								{/* <!-- Dispatch Details ========== --> */}
								<div className="row align-items-center mb-3">
									<div className="col-md">
										<h2 className="title">Dispatch Details </h2>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Contact name : &nbsp;</label>
											<label className="border-0 fw-550">{DispatchDetails.ContactName}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Email : &nbsp;</label>
											<label className="border-0 fw-550">{DispatchDetails.Email}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Alternate Email : &nbsp;</label>
											<label className="border-0 fw-550">{DispatchDetails.AltEmail}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Address : &nbsp;</label>
											<label className="border-0 fw-550">{DispatchDetails.Address}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Phone : &nbsp;</label>
											<label className="border-0 fw-550">{this.ChangePhoneFormat(DispatchDetails.Phone)}</label>

										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Alternate Phone : &nbsp;</label>
											<label className="border-0 fw-550">{this.ChangePhoneFormat(DispatchDetails.AltPhone)}</label>

										</div>
									</div>

								</div>
								<div className="hr"></div>

								{/* <!-- Billing Details ========== --> */}
								<div className="row align-items-center mb-3">
									<div className="col-md">
										<h2 className="title">Billing Details </h2>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Contact name : &nbsp;</label>
											<label className="border-0 fw-550">{BillingDetails.ContactName}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Email : &nbsp;</label>
											<label className="border-0 fw-550">{BillingDetails.Email}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Alternate Email : &nbsp;</label>
											<label className="border-0 fw-550">{BillingDetails.AltEmail}</label>

										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Address : &nbsp;</label>
											<label className="border-0 fw-550">{BillingDetails.Address}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Phone : &nbsp;</label>
											<label className="border-0 fw-550">{this.ChangePhoneFormat(BillingDetails.Phone)}</label>
										</div>
									</div>
									<div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
										<div className="form-group">
											<label>Alternate Phone : &nbsp;</label>
											<label className="border-0 fw-550">{this.ChangePhoneFormat(BillingDetails.AltPhone)}</label>

										</div>
									</div>
									
								</div>
								<div className="hr"></div>

								<div className="form-group btn-list">
								<a href="#" data-toggle="modal" className="btn btn-secondary" data-target="#sendUpdateRequest"><span>Send an Update Request</span></a>
									{parseInt(this.props.IsSuperAdmin) === 0 && <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#changePassword" onClick={this.SetDispatchEmail}><span>Change Password</span></button>}
								</div>
							</div>
						</div>
						{/* <!-- Profile images =====================================--> */}
						

					</div>
				</main>

				<div className="modal fade" id="changePassword" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<ChangePassword dispatchEmail={this.state.DispatchDetails.Email}></ChangePassword>
				</div>
				
				<div className="modal fade" id="sendUpdateRequest" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">

					<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="modal-title" id="exampleModalLabel">Your Request Will be Sent</h2>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onModalClose}>
									<img src="images/close.svg" alt="" />
								</button>
							</div>
							<div className="modal-body">
								<form className="change-password">

									<div className="form-group">
											<label htmlFor="subject" className="col-form-label">Subject:</label>
											<div>
												<input type="text" name="Subject" className="form-control" id="reqSubject" maxLength="200" value={this.state.Subject} onChange={this.handleChange} ></input>
												{this.state.Errors.Subject && this.state.Errors.Subject !== '' ? <span className="errorMsg">{this.state.Errors.Subject}</span> : null}
											</div>
										 
									</div>
									<div className="form-group">

										<label htmlFor="message" className="col-form-label">Message:</label>
										<div>
											<textarea className="form-control" rows="4" name="Message" id="Message" maxLength="2000" value={this.state.Message} onChange={this.handleChange}></textarea>
											{this.state.Errors.Message && this.state.Errors.Message !== '' ? <span className="errorMsg">{this.state.Errors.Message}</span> : null} 
										</div>
										
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" onClick={this.validateRequestOnSend}><span>Submit</span></button>
								<button type="button" id="btnCloseRequestModal" className="btn btn-secondary" data-dismiss="modal" onClick={this.onModalClose}><span>Cancel</span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
    Token: state.login.Token,
    ProviderID: state.login.ProviderID,
    IsSuperAdmin: state.login.IsSuperAdmin
})
export default connect(mapStateToProps)(MyProfile)