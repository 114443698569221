import axios from 'axios'
import store from '../_redux/store';
import iZitoast from 'izitoast'
import {LOGOUT_ACTIONS} from '../_redux/actions/types'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const state = store.getState();
    const token = state.login.Token;
    const isLoggedIn= state.login.IsLoggedIn;
    config.headers['userAgent'] = window.navigator.userAgent;
    if (isLoggedIn == 1) {
        config.headers['PortalToken'] = token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
});

// Add a response interceptor
axios.interceptors.response.use(
    function(response) { 
        return response;
    }, 
    function(error) {
        errorResponseHandler(error);
        return Promise.reject(error);
    });

function errorResponseHandler(error) {
    //Quasar Toast Schema
    let message = 'Network error';

    //Setup Error Message
    if (typeof error !== 'undefined') {
        if (error.hasOwnProperty('message')) {
            message = error.message
        }
    }

    if (typeof error.response !== 'undefined') {
        //Setup Generic Response Messages
        if (error.response.status === 401) {
            store.dispatch({
                type: LOGOUT_ACTIONS,
                payload: {}
            })
            message=''
        } else if (error.response.status === 404) {
            message = 'API route is missing or undefined'
        } else if (error.response.status === 405) {
            message = 'API route method not allowed'
        } else if (error.response.status === 422) {
            //Validation Message
        } else if (error.response.status >= 500) {
            message = 'Server error'
        }

        //Try to Use the Response Message
        if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (error.response.data.hasOwnProperty('message') && error.response.data.message.length > 0) {
                message = error.response.data.message
            }
        }
    }

    //Toast the Message
    if (message.length > 0) {
        iZitoast.error({ title: 'Error: ', message: message});
        // Toast.create.negative(message)
    }
}