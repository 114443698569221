import { LOGIN_ACTIONS, LOGOUT_ACTIONS, LOGINAS_ACTIONS } from '../actions/types'

const initialState = {
    Token: localStorage.getItem('Token'),
    IsLoggedIn: localStorage.getItem('IsLoggedIn'),
    UserName: localStorage.getItem('UserName'),
    ProviderID: localStorage.getItem('ProviderID'),
    ProviderNetTerms: localStorage.getItem('ProviderNetTerms'),
    IsSuperAdmin: localStorage.getItem('IsSuperAdmin')
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_ACTIONS:
            const IsLoggedIn = action.payload.IsLoggedIn;
            const NewToken = action.payload.Token;
            if (IsLoggedIn) {
                localStorage.setItem('Token', NewToken);
                localStorage.setItem('IsLoggedIn', IsLoggedIn);
                localStorage.setItem('Time', new Date())
                localStorage.setItem('UserName', action.payload.UserName);
                localStorage.setItem('ProviderNetTerms', action.payload.ProviderNetTerms);
                localStorage.setItem('ProviderID', action.payload.ProviderID);
                localStorage.setItem('IsSuperAdmin', action.payload.IsSuperAdmin);
            }
            else {
                localStorage.removeItem('Token');
                localStorage.setItem('IsLoggedIn', IsLoggedIn);
                localStorage.removeItem('Time');
                localStorage.removeItem('ProviderNetTerms');
                localStorage.removeItem('ProviderID');
                localStorage.removeItem('IsSuperAdmin');
            }
            return {
                ...state,
                Token: NewToken,
                IsLoggedIn: IsLoggedIn,
                UserName: action.payload.UserName,
                ProviderID: action.payload.ProviderID,
                ProviderNetTerms: action.payload.ProviderNetTerms,
                IsSuperAdmin: action.payload.IsSuperAdmin
            };
        case LOGOUT_ACTIONS:
            localStorage.removeItem('Token');
            localStorage.setItem('IsLoggedIn', 0);
            localStorage.removeItem('ProviderNetTerms');
            localStorage.removeItem('Time')
            localStorage.removeItem('ProviderID');
            localStorage.removeItem('IsSuperAdmin');
            const localRememberMe = localStorage.getItem('RememberMe');
            if(localRememberMe ? localRememberMe != 'true' : false)
            {
                localStorage.removeItem('UserName');
            }
            if(localStorage.getItem('RememberMe'))
            return {
                ...state,
                Token: '',
                IsLoggedIn: 0
            };
        case LOGINAS_ACTIONS:
            localStorage.setItem('ProviderID', action.payload.ProviderID);
            return {
                ...state,
                ProviderID: action.payload.ProviderID
            };
        default:
            return state
    }
}