import React, { Component } from 'react'

export default class FAQs extends Component {
    render() {
        return (
            <main className="main-content">
                <div className="container-fluid" id="myGroup">
                    <div className="title">
                        <h1>Frequently Asked Questions</h1>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq1" role="button" aria-expanded="false" aria-controls="faq1">I have a question or concern about an appointment that is scheduled for today. Who should I contact?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq1" data-parent="#myGroup">
                                <div className="inner">
                                    <ul className="unordered-list">
                                        <li>For appointments for today, please call us at 866-941-7878 and Select Option 2 for assistance.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button type="button" className="accordion-toggle collapsed" data-toggle="collapse" data-target="#faq2" aria-expanded="false" aria-controls="faq2">I have a question or concern that is NOT related to an appointment for today.  Who should I contact?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq2" data-parent="#myGroup">
                                <div className="inner">
                                    <ul className="unordered-list">
                                        <li>
                                            Email is the most efficient method of contacting us about concerns not related to an appointment for today.
                                    </li>
                                        <li>
                                            For Invoices/Billing Questions: email Billing@theprocare.com  or call 866-941-7878, Option 6.
                                    </li>
                                        <li>
                                            For Provider Contract Questions, email Providerrelations@theprocare.com or 866-941-7878, Option 5.
                                    </li>
                                        <li>
                                            For Referral Questions, email Dispatch@theprocare.com or call 866-941-7878, Option 4.
                                    </li>
                                        <li>
                                            For New Appointments, email Customerservice@theprocare.com or call 866-941-7878, Option 1.
                                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq3" role="button" aria-expanded="false" aria-controls="faq3">How do I submit my invoice to ProCare?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq3" data-parent="#myGroup">
                                <div className="inner">
                                    <ul className="unordered-list">
                                        <li>You may submit your invoice via the ”Submit an Invoice” link here in your Provider Portal, via the Provider Tab on our website at www.theprocare.com,  or by emailing your invoice to our Billing department at Billing@theprocare.com.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq4" role="button" aria-expanded="false" aria-controls="faq4">How long does it take for invoices that I submitted to show up on my portal?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq4" data-parent="#myGroup">
                                <div className="inner">
                                    <ul className="unordered-list">
                                        <li>Please allow up to 72 hours for any recently submitted invoices to reflect on your portal.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq4_1" role="button" aria-expanded="false" aria-controls="faq4_1">Will all of my PO#s/Invoices show on this portal?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq4_1" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        While you can submit an invoice for any PO#; the portal will only display payment statuses on PO#s/Invoices for services provided on 01/21/2020 and later.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq5" role="button" aria-expanded="false" aria-controls="faq5">When can I expect to be paid for assignments that I complete?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq5" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        Our Standard payment term is Net 30.  Your payment will be processed within 30 days of our Billing Team receiving your clean invoice. Please refer to the Payment Status page here in your Portal for specific payment details.
                                        <br /> <br />
                                        Please note that incomplete or inaccurate information on your invoice could delay payment, so please ensure that the information is correct on your invoice before submitting.
                                        <br /> <br />
                                        We also offer ACH / Direct Deposit, which is a convenient and faster way for you to receive your payment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq6" role="button" aria-expanded="false" aria-controls="faq6">How do I sign up to receive my payments by Direct Deposit?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq6" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        In order to sign up for Automated Clearing House (ACH) Payment / Direct Deposit, you will need to complete an ACH Form and submit it to ProCare along with a Voided Check.
                                        <br /> <br />
                                        Please contact our Provider Relations Team by clicking on the “Send an Update Request” link that is on your “My Profile” page. In the pop-up box, please request to sign up for ACH/Direct Deposit.  Our Provider Relations Team will send you the ACH Form to fill out and specific sign-up instructions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq7" role="button" aria-expanded="false" aria-controls="faq7">I submitted my invoice for the assignment I completed to ProCare. Where is my payment?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq7" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        For payment information, please click on the “Billing” button that is on the left side of the page in your portal, and then select “Payment Status.”
                                        <br /> <br />
                                        Here, you may search by either by the Purchase Order Number (PO #) or by the Date of Service to receive specific details about your payment and when it will be remitted to you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq8" role="button" aria-expanded="false" aria-controls="faq8">Why is a payment on my Payment Status Page showing a different amount than what I submitted on my invoice to ProCare?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq8" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        Our Billing Team remits payment according to the rates, fees, and/or payment terms that were on the Purchase Order Authorization that the provider received from our Dispatch Team.
                                        <br /> <br />
                                        If a provider submits an invoice with different rates, fees, and/or payment terms than what the Purchase Order authorized, then our Billing Team will adjust the provider’s payment to what was authorized on the Purchase Order.  Our Billing Team will also provide an Explanation of Payment (EOP) code that explains what was adjusted on the payment and why.
                                        <br /> <br />
                                        On your Payment Status page, please click on the link in the “Adjustment” column to view the EOP code that applied to that Purchase Order adjustment.
                                        <br /> <br />
                                        If you still have questions after viewing the Explanation of Payment, please contact our Billing Team by clicking on the “Query” button for that PO #.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq9" role="button" aria-expanded="false" aria-controls="faq9">How do I make changes to my provider information such as my name, address, email, or phone number?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq9" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        Our Provider Relations Team handles provider information changes. You may submit an Update Request to Provider Relations by clicking on the “Send an Update Request” link that is on your “My Profile” page.
                                        <br /> <br />
                                        In the pop-up box, please state the information that you need to change.  Your message will be sent directly to Provider Relations, so please make sure your message is specific and accurate. You will receive a confirmation email from Provider Relations when your information is changed.
                                        <br /> <br />
                                        Please note that name or address changes may require new documentation from you before Provider Relations can process your change request.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq10" role="button" aria-expanded="false" aria-controls="faq10">I now offer more services and/or cover more areas. How do I expand my services and coverage areas with ProCare?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq10" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        Our Provider Relations Team handles all provider contracting matters, including providers’ available services and coverage areas. You may submit an Update Request to Provider Relations by clicking on the “Send an Update Request” link that is on your “My Profile” page.
                                        <br /> <br />
                                        In the pop-up box, please state the additional services and/or coverage areas you want to add.  Your message will be sent directly to Provider Relations, so please make sure your message is specific and accurate. A Provider Relations Team Member will contact you regarding your Update Request.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion">
                        <div className="accordion-block">
                            <div className="accordion-header">
                                <button data-toggle="collapse" className="accordion-toggle collapsed" href="#faq11" role="button" aria-expanded="false" aria-controls="faq11">I’ve sent my provider credentials and documentation to ProCare. Why do I keep receiving an email from Provider Relations about Missing Information?</button>
                            </div>
                            <div className="collapse accordion-body" id="faq11" data-parent="#myGroup">
                                <div className="inner">
                                    <p className="p-0">
                                        Our system is programmed to send automated emails to providers if any documents are missing or will expire soon in the provider’s file. Once a Provider Relations Team Member processes the submitted documentation, the system is updated, and the automated emails will cease if all of the required documentation for the provider is now on file and current.
                                        <br /> <br />
                                        Please allow time for the Provider Relations Team to process the documentation you sent. You will receive a confirmation email from Provider Relations once your submitted documents are processed, and you will be notified if anything is needed after that. Thank you for your patience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}