import React, { Component } from 'react'
import iZitoast from 'izitoast'
import { connect } from 'react-redux'
import axios from 'axios'
import Config from '../../Config'

class ChangePassword extends Component {
    constructor(props) {
		super(props);
		this.state = {
			NewPassword: "",
			CurrentPassword: "",
			ConfirmPassword: "",
			Errors: { CurrentPassword: "", NewPassword: "", ConfirmPassword: "" },
			IsPasswordValid: true,
			hideCurrentPassword: true,
			hideNewPassword: true,
			hideConfirmPassword: true,
			PassowrdValidations: ""
        }
        this.validatePassword = this.validatePassword.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.onPasswordBlur = this.onPasswordBlur.bind(this);
		this.onModalClose = this.onModalClose.bind(this);
		this.validatePasswordOnSave = this.validatePasswordOnSave.bind(this);
		this.toggleShow = this.toggleShow.bind(this);
		this.toggleHide = this.toggleHide.bind(this);
    }
    
    handlePasswordChange(e) {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		
		this.setState({
			...this.state,
			[fieldName]: fieldValue,
			Errors: {
				...this.state.Errors,
				[fieldName]: ""
			}

		}, () => this.validatePassword(fieldName));
    }
    onPasswordBlur(e) {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		this.validatePassword(fieldName);
    }
        
    validatePassword(fieldName) {
		fieldName = fieldName.trim();
		let fieldValue = this.state[fieldName].trim();
		let Errors = this.state.Errors;
		let IsPasswordValid = this.state.IsPasswordValid;
		let PassowrdValidations = this.state.PassowrdValidations;
		switch (fieldName) {
			case "CurrentPassword":
				if (!fieldValue || fieldValue.trim() === '') {
					IsPasswordValid = false;
					Errors.CurrentPassword = "*Please enter current password"
				} else {

				}
				break;
			case "NewPassword":
				PassowrdValidations = '';
				if (!fieldValue || fieldValue.trim() === '') {
					IsPasswordValid = false;
					Errors.NewPassword = "*Please enter new password"
				}
				else {
					if (!(/[!@#$^&*)(+=._-]/.test(fieldValue))) { //For special character
						PassowrdValidations += '*Password should contain 1 special character \n';
						IsPasswordValid = false;
					}
					if (!new RegExp(/[0-9]/).test(fieldValue)) {
						PassowrdValidations += '*Password should contain 1 number \n';
						IsPasswordValid = false;
					}
					if (!new RegExp(/[A-Z]/).test(fieldValue)) {
						PassowrdValidations += '*Password should contain 1 capital letter \n';
						IsPasswordValid = false;
					}
					if (fieldValue.length < 8) {
						PassowrdValidations += '*Password should contain minimum 8 characters';
						IsPasswordValid = false;
					}

					this.setState({
						...this.state,
						PassowrdValidations: PassowrdValidations
					})
				}
				if(this.state.ConfirmPassword == undefined || this.state.ConfirmPassword.length <= 0){
					break;
				}
			case "ConfirmPassword":
				if (!this.state.ConfirmPassword || this.state.ConfirmPassword.trim() === '') {
					IsPasswordValid = false;
					Errors.ConfirmPassword = "*Please enter password to confirm";
					
				}
				else if ((this.state.NewPassword && this.state.ConfirmPassword) && this.state.NewPassword.trim() !== this.state.ConfirmPassword.trim()) {
					IsPasswordValid = false;
					Errors.ConfirmPassword = "*New and confirm password do not match";
				}
				else{
					Errors.ConfirmPassword = "";
				}
				break;
		}

		this.setState({
			...this.state,
			Errors: Errors,
			IsPasswordValid: IsPasswordValid,
			PassowrdValidations: PassowrdValidations
		});
    }
    onModalClose() {
		this.setState({
			...this.state,
			CurrentPassword: "",
			NewPassword: "",
			ConfirmPassword: "",
			Errors: {},
			IsPasswordValid: true,
			PassowrdValidations: ""
		})
    }
    toggleShow(e) {
		e.preventDefault();
		let passwordType;
		let type = e.target.getAttribute("name");

		if (type === 'btnCurrentPassword') {
			passwordType = 'hideCurrentPassword';
		} else if (type === 'btnNewPassword') {
			passwordType = 'hideNewPassword';
		} else if (type === 'btnConfirmPassword') {
			passwordType = 'hideConfirmPassword';
		}

		this.setState({
			...this.state,
			[passwordType]: false
		});

	}

	toggleHide(e) {
		e.preventDefault();
		let passwordType;
		let type = e.target.getAttribute("name");

		if (type === 'btnCurrentPassword') {
			passwordType = 'hideCurrentPassword';
		} else if (type === 'btnNewPassword') {
			passwordType = 'hideNewPassword';
		} else if (type === 'btnConfirmPassword') {
			passwordType = 'hideConfirmPassword';
		}

		this.setState({
			...this.state,
			[passwordType]: true
		});

	}

    validatePasswordOnSave() {
		const CurrentPassword = this.state.CurrentPassword.trim()
		const NewPassword = this.state.NewPassword.trim()
		const ConfirmPassword = this.state.ConfirmPassword.trim()
		const Errors = this.state.Errors
		let IsPasswordValid = true;
		let PassowrdValidations = this.state.PassowrdValidations;

		if (!CurrentPassword && CurrentPassword.trim() === '') {
			IsPasswordValid = false;
			Errors.CurrentPassword = "*Please enter current password";
		}
		else {
			Errors.CurrentPassword = "";
		}
		if (!NewPassword && NewPassword.trim() === '') {
			IsPasswordValid = false;
			Errors.NewPassword = "*Please enter new password";
		}
		else {
			let flag = false;
			if (!(/[!@#$^&*)(+=._-]/.test(NewPassword))) { //For special character
				PassowrdValidations = '*Password should contain 1 special character \n';
				IsPasswordValid = false;
				flag = true;
			}
			if (!new RegExp(/[0-9]/).test(NewPassword)) {
				PassowrdValidations += '*Password should contain 1 number \n';
				IsPasswordValid = false;
				flag = true;
			}
			if (!new RegExp(/[A-Z]/).test(NewPassword)) {
				PassowrdValidations += '*Password should contain 1 capital letter \n';
				IsPasswordValid = false;
				flag = true;
			}
			if (NewPassword.length < 8) {
				PassowrdValidations += '*Password should contain minimum 8 characters';
				IsPasswordValid = false;
				flag = true;
			}
			if (flag) {
				Errors.NewPassword = "";
			}
			this.setState({
				...this.state,
				PassowrdValidations: PassowrdValidations
			})
		}
		if (!ConfirmPassword && ConfirmPassword.trim() === '') {
			IsPasswordValid = false;
			Errors.ConfirmPassword = "*Please enter password to confirm";
		}
		else if ((NewPassword && ConfirmPassword) && NewPassword !== ConfirmPassword) {
			IsPasswordValid = false;
			Errors.ConfirmPassword = "*New and confirm password do not match";
		}
		else {
			Errors.ConfirmPassword = "";
		}

		this.setState({
			...this.state,
			CurrentPassword: CurrentPassword,
			NewPassword: NewPassword,
			ConfirmPassword: ConfirmPassword,
			Errors: Errors,
			IsPasswordValid: IsPasswordValid,
			PassowrdValidations: PassowrdValidations
		}, () => {
			if (IsPasswordValid) {
				const data = {
					AccessToken: this.props.Token,
					CurrentPassword: CurrentPassword,
					NewPassword: NewPassword,
					ConfirmPassword: ConfirmPassword,
					LoginID : this.props.UserName,
					DispatchEmail : this.props.dispatchEmail,
					ProviderID : parseInt(this.props.ProviderID)
				}
				axios.post(Config.API_URL + '/ChangePassword/Save', data)
					.then(res => {
						if (res.data.errorMsg && res.data.errorMsg !== '') {
							iZitoast.destroy();
							iZitoast.error({ message: res.data.errorMsg, timeout: 7000 });
						}
						else {
							this.setState({
								...this.state,
								CurrentPassword: "",
								NewPassword: "",
								ConfirmPassword: "",
								IsPasswordValid: true
							}, () => {
								iZitoast.destroy();
								iZitoast.success({ message: "Your password has been updated successfully!", timeout: 5000 });

								document.getElementById('btnCloseModal').click();
							});

						}
					})
			}
		});
	}

    render() {
        return (
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="exampleModalLabel">Change Password</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onModalClose}>
                            <img src="images/close.svg" alt="" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="change-password">
                            <div className="form-group">
                                <label htmlFor="current-Password" className="col-form-label">Current Password:</label>
                                <div>
                                    <input type={this.state.hideCurrentPassword ? "password" : "text"} name="CurrentPassword" className="form-control" id="currentPassword" value={this.state.CurrentPassword} onChange={this.handlePasswordChange} onBlur={this.onPasswordBlur}></input>
                                    <span name="btnCurrentPassword" onMouseDown={this.toggleShow} onTouchStart={this.toggleShow} onMouseUp={this.toggleHide} onMouseLeave={this.toggleHide} onTouchEnd={this.toggleHide} title="Show Password" className={this.state.hideCurrentPassword ? "password-show show" : "password-show show active"}></span>
                                </div>
                                {this.state.Errors.CurrentPassword && this.state.Errors.CurrentPassword !== '' ? <span className="errorMsg">{this.state.Errors.CurrentPassword}</span> : null}
                            </div>
                            <div className="form-group">

                                <label htmlFor="new-Password" className="col-form-label">New Password:</label>
                                <div>
                                    <input type={this.state.hideNewPassword ? "password" : "text"} name="NewPassword" className="form-control" id="newPassword" value={this.state.NewPassword} onChange={this.handlePasswordChange} onBlur={this.onPasswordBlur}></input>
                                    <span name="btnNewPassword" onMouseDown={this.toggleShow} onTouchStart={this.toggleShow} onMouseUp={this.toggleHide} onMouseLeave={this.toggleHide} onTouchEnd={this.toggleHide} title="Show Password" className={this.state.hideNewPassword ? "password-show show" : "password-show show active"}></span>
                                </div>
								{this.state.PassowrdValidations && this.state.PassowrdValidations !== '' && <div className="errorMsg">{this.state.PassowrdValidations.split('\n').map((item, key) => { return (<div key={key}>{item}</div>) })}</div>}
                                {this.state.Errors.NewPassword && this.state.Errors.NewPassword !== '' ? <span className="errorMsg">{this.state.Errors.NewPassword}</span> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className="col-form-label">Confirm Password:</label>
                                <div>
                                    <input type={this.state.hideConfirmPassword ? "password" : "text"} name="ConfirmPassword" className="form-control" id="confirmPassword" value={this.state.ConfirmPassword} onChange={this.handlePasswordChange} onBlur={this.onPasswordBlur}></input>
                                    <span name="btnConfirmPassword" onMouseDown={this.toggleShow} onTouchStart={this.toggleShow} onMouseUp={this.toggleHide} onMouseLeave={this.toggleHide} onTouchEnd={this.toggleHide} title="Show Password" className={this.state.hideConfirmPassword ? "password-show show" : "password-show show active"}></span>
                                </div>
                                {this.state.Errors.ConfirmPassword && this.state.Errors.ConfirmPassword !== '' ? <span className="errorMsg">{this.state.Errors.ConfirmPassword}</span> : null}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.validatePasswordOnSave}><span>Save</span></button>
                        <button type="button" id="btnCloseModal" className="btn btn-secondary" data-dismiss="modal" onClick={this.onModalClose}><span>Cancel</span></button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
	UserName: state.login.UserName,
	ProviderID: state.login.ProviderID,
	Token: state.login.Token
})
export default connect(mapStateToProps, { })(ChangePassword)