import React, { Component } from 'react'
import iZitoast from 'izitoast'
import Select from 'react-select'
import { connect } from 'react-redux'
import { GetUpdateProviderForAdmin } from '../../_redux/actions/sessionActions'

class SelectProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Providers : [],
            selectedProvider: null
        }
		this.handleSelectClick = this.handleSelectClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleGetProviderCallBack = this.handleGetProviderCallBack.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this)
    }
    componentDidMount() {
        this.BindProviders();
    }
    
    BindProviders()
    {
        const GetData = {
            "Flag": false,
            "ProviderID": 0
        }
        this.props.GetUpdateProviderForAdmin(GetData, this.handleGetProviderCallBack);
    }
    handleGetProviderCallBack(res, flag) {
        if (flag == false) {
            let providers = res.map(p => ({ value: p.providerID, label: p.providerID + ' - ' + p.providerName }));
            let selectedProvider = providers.filter(p => parseInt(p.value) == parseInt(this.props.ProviderID));

            this.setState({
                Providers: providers,
                selectedProvider: selectedProvider
            });
        }
        else {
            window.location.reload();
        }
    }
    handleChange(event) {
        this.setState({selectedProvider: event});
    }
	handleSelectClick(event) {
		event.preventDefault();
        let providerID = 0;
        var toast = document.querySelector('.searchError');
		if (toast != null)
			iZitoast.hide({ transitionOut: 'fadeOutUp' }, toast);

		if (this.state.selectedProvider == null) {
			iZitoast.error({ class: 'searchError', message: "Please select provider first" });
			return false;
		}
		else {
            providerID = this.state.selectedProvider.value;
        }
        
        if (providerID > 0)
        {
            const GetData = {
                "Flag": true,
                "ProviderID": providerID
            }
            this.props.GetUpdateProviderForAdmin(GetData, this.handleGetProviderCallBack);
        }
    }
    handleCloseClick(event) {
        let provider = this.state.Providers.filter(p => parseInt(p.value) == parseInt(this.props.ProviderID));
        this.setState({selectedProvider: provider});
    }
    
    render() {
        return (
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="exampleModalLabel">Select Provider</h2>
                        {parseInt(this.props.ProviderID) > 0 &&
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseClick}>
                            <img src="images/close.svg" alt="" />
                        </button>
                        }
                    </div>
                    <div className="modal-body">
                        <form className="select-provider">
                            <div className="row">
                                <div className=" col-md-12 col-lg-8">
                                    <div className="form-group">
                                        <Select options={this.state.Providers} onChange={this.handleChange} value={this.state.selectedProvider} isClearable="true" />
                                    </div>
                                </div>
                                <div className=" col-md-12 col-lg-4">
                                    <div className="form-group">
                                        <button type="submit" id="btnSelect" className="btn btn-primary mr-2" onClick={this.handleSelectClick}><span>Select</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ProviderID: state.login.ProviderID
})
export default connect(mapStateToProps, { GetUpdateProviderForAdmin })(SelectProvider)
