import React, {Component} from 'react'

export default class NewAppointment extends Component{
    render(){
        return(
            <main className="main-content">
                <div className="container-fluid">
                    <div className="title">
                        <h1>New Appointments</h1>
                    </div>
                    <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                        <div className="form-group">
                            Click below to enter details and submit new appointment
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                            <div className="form-group">
                                <a className="btn btn-primary" href="https://theprocare.com/patient-referral-form/" target="_blank">Submit</a>
                            </div>
                        </div>
                    </div>
                </div>
           </main>
        )
    }
}
