import 'izitoast/dist/css/iziToast.min.css'
import iZitoast from 'izitoast'

iZitoast.settings({
    timeout: 15000, // set timeout to 0 to prevent auto close notification
    resetOnHover: true,
    // icon: '', // icon class
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    // close: false,
    position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
  });