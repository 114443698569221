import React, { Component } from 'react'

class TnC extends Component {
    constructor(props) {
        super(props);

        this.state = {
            AcceptTnC: false
        }
        this.AcceptTnCChange = this.AcceptTnCChange.bind(this);
    }
    // componentDidMount() {
    //     window.$('#TnC-Modal').modal('show');
    // }
    componentWillUnmount() {
        window.$('.modal').modal("hide");
    }
    AcceptTnCChange() {
        this.props.HideTnCACTIONS();
    }
    render() {
        return (
            <div>
                <div className="modal fade" id="TnC-Modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title">Terms &amp; Conditions</h2>
                            </div>
                            <div className="modal-body text-dark">
                                <strong>NOTICE: THIS IS A SECURE SITE AVAILABLE TO AUTHORIZED USERS ONLY!</strong>
                                <br />
                                <br />
                                By clicking the login button, you confirm that you are an Approved Provider for ProCare Transportation and Language Services, or the Authorized Agent of an Approved Provider for ProCare Transportation and Language Services.
                                <br />
                                <br />
                                ANY AND ALL INFORMATION OBTAINED THROUGH THIS SITE IS SUBJECT TO HIPAA RULES AND REGULATIONS. ANY VIOLATION OF THESE RULES AND REGULATIONS AND/OR UNAUTHORIZED USE OF ANY INFORMATION IS STRICTLY PROHIBITED!
                                <br />
                                <br />
                                <strong>I understand and agree to the above statement.</strong>
                            </div>
                            <div className="modal-footer justify-content-end">
                                <button type="button" tabIndex={10} className="btn btn-secondary" data-dismiss="modal" ><span>Close</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default TnC