import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import axios from 'axios'
import Config from '../../Config'
import InlineLoader from '../../utilities/InlineLoader'
import iZitoast from 'izitoast'
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from 'react-tooltip'
import store from '../../_redux/store';

export default class PaymentStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			SearchBy: 'DOS',
			StartDate: null,
			EndDate: null,
			ReferralNo: '',
			ProviderID: '',
			ProviderName: '',
			ProviderNetTerms: '',
			IsCCProviderExists: false,
			BillingQuery: {
				PO: '',
				Subject: '',
				Message: ''
			},
			EOPDescription: '',

			PaymentData: [],
			currentPageData: [],
			PageIndex: 1,
			PageSize: 10,
			currentSortCol: 'dateOfService',
			sortOrder: 'DESC',
			PaymentDataLoaded: false,
			IsDisplayLoader: false
		}

		this.handleSearchByChange = this.handleSearchByChange.bind(this);
		this.handleStartDateChange = this.handleStartDateChange.bind(this);
		this.handleEndDateChange = this.handleEndDateChange.bind(this);
		this.handleReferralNoChange = this.handleReferralNoChange.bind(this);
		this.handleClearClick = this.handleClearClick.bind(this);
		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.handleQueryClick = this.handleQueryClick.bind(this);

		this.handleBQueryCancelClick = this.handleBQueryCancelClick.bind(this);
		this.handleBQuerySendClick = this.handleBQuerySendClick.bind(this);
		this.handleBQueryMessageChange = this.handleBQueryMessageChange.bind(this);
		this.handleBQuerySubjectChange = this.handleBQuerySubjectChange.bind(this);
		this.handleEOPReasonClick = this.handleEOPReasonClick.bind(this);
		this.handleEOPCancelClick = this.handleEOPCancelClick.bind(this);

		this.setPageSize = this.setPageSize.bind(this);
		this.setPageIndex = this.setPageIndex.bind(this);
		this.sortData = this.sortData.bind(this);
	}
	componentDidMount() {
		const state = store.getState();
		const providerNetTerms = state.login.ProviderNetTerms;
		this.setState({
			ProviderNetTerms: providerNetTerms,
			IsCCProviderExists: (providerNetTerms === 'CC-Amex' || providerNetTerms === 'CC-Visa') ? true : false
		});
	}
	componentWillUnmount() {
		window.$('.modal').modal("hide");
	}
	setPageSize(e) {
		let maxPageIndex = Math.ceil(this.state.PaymentData.length / e.target.value);
		this.setState({
			PageSize: e.target.value,
			PageIndex: this.state.PageIndex > maxPageIndex ? maxPageIndex : this.state.PageIndex
		}, () => this.setPageData(this.state.PaymentData));
	}
	setPageIndex(e) {
		this.setState({
			PageIndex: parseInt(e.target.name)
		}, () => this.setPageData(this.state.PaymentData));
	}
	setPageData(data) {
		this.setState({
			currentPageData: data.length > 0 ? data.slice(this.state.PageSize * this.state.PageIndex - this.state.PageSize, this.state.PageSize * this.state.PageIndex) : []
		});
	}

	sortData(Index, DType) {
		let sortedData = [];

		sortedData = [].concat(this.state.PaymentData)
			.sort((a, b) => sortEquation(a, b, this.state.sortOrder))
			.map((item, i) => { return item; }
			);
		let newOrder = this.state.sortOrder == 'DESC' ? "ASC" : 'DESC';
		this.setState({
			PaymentData: sortedData,
			sortOrder: newOrder,
			currentSortCol: Index
		}, () => this.setPageData(this.state.PaymentData))

		function sortEquation(a, b, sortOrder) {
			if (DType == 'number') {
				return sortOrder == 'DESC' ? parseInt(a[Index]) - parseInt(b[Index]) : parseInt(b[Index]) - parseInt(a[Index]);
			}
			else if (DType == 'date') {
				return sortOrder == 'DESC' ? dateToInt(a[Index]) - dateToInt(b[Index]) : dateToInt(b[Index]) - dateToInt(a[Index]);
			}
			else if (DType == 'string') {
				return (sortOrder == 'DESC' ? a[Index] > b[Index] : b[Index] > a[Index]) ? 1 : -1;
			}
		}
		function dateToInt(date) {
			let [m, d, y] = date.split('/');
			if (isNaN(parseInt(y + m + d))) {
				return -1;
			}
			return parseInt(y + m + d);
		}
	}
	renderPagination() {
		let maxPageIndex = Math.ceil(this.state.PaymentData.length / this.state.PageSize);
		let pageButtons = []
		let startIndex = 1;
		let lastIndex = 5;
		if (this.state.PageIndex > 3) {
			if (this.state.PageIndex + 2 <= maxPageIndex) {
				startIndex = this.state.PageIndex - 2;
				lastIndex = Math.min(this.state.PageIndex + 2, maxPageIndex)
			}
			else {
				startIndex = Math.max(maxPageIndex - 4, 1);
				lastIndex = maxPageIndex;
			}
		}
		for (startIndex; startIndex <= Math.min(lastIndex, maxPageIndex); startIndex++) {
			pageButtons.push(<li key={startIndex}><a onClick={this.setPageIndex} name={startIndex} className={startIndex == this.state.PageIndex ? "active" : ""}>{startIndex}</a></li>)
		}
		return (
			<div>
				<div className="grid-bottom row">
					<div className="col-lg">
						<div className="show-record">
							<select onChange={this.setPageSize} name="PageSize" value={this.state.PageSize} className="custom-select" style={{ height: "38px" }}>
								<option value="10">10</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
							<span className="form-label">Records per page</span>
						</div>
					</div>
					<div className="col-lg">

						<div className="paginetion float-right">
							{maxPageIndex != 1 && <ul style={{ cursor: "pointer" }}>
								{maxPageIndex > 5 && (this.state.PageIndex != 1 ? <li><a onClick={this.setPageIndex} name={1} className="prevar first"></a></li> : <li><a style={{ cursor: "default" }} className="prevar first opa-4"></a></li>)}
								{maxPageIndex > 5 && (this.state.PageIndex != 1 ? <li><a onClick={this.setPageIndex} name={this.state.PageIndex - 1} className="prevar"></a></li> : <li><a style={{ cursor: "default" }} className="prevar opa-4"></a></li>)}
								{pageButtons.map(i => { return i; })}
								{maxPageIndex > 5 && (this.state.PageIndex != maxPageIndex ? <li><a onClick={this.setPageIndex} name={this.state.PageIndex + 1} className="nxtar"></a></li> : <li><a style={{ cursor: "default" }} className="nxtar opa-4"></a></li>)}
								{maxPageIndex > 5 && (this.state.PageIndex != maxPageIndex ? <li><a onClick={this.setPageIndex} name={maxPageIndex} className="nxtar last"></a></li> : <li><a style={{ cursor: "default" }} className="nxtar last opa-4"></a></li>)}
							</ul>}

						</div>
						<div className="float-right" style={{ margin: "11px 25px" }}>
							{this.state.PageIndex * this.state.PageSize - this.state.PageSize + 1} - {Math.min(this.state.PageIndex * this.state.PageSize, this.state.PaymentData.length)} of {this.state.PaymentData.length}
						</div>
					</div>
				</div>

			</div>);
	}
	handleSearchByChange(event) {
		let searchBy = event.target.checked ? (event.target.id === 'rbDateOfService' ? 'DOS' : 'PO') : null
		this.setState({
			SearchBy: searchBy,
			StartDate: searchBy === 'DOS' ? this.state.StartDate : null,
			EndDate: searchBy === 'DOS' ? this.state.EndDate : null,
			ReferralNo: searchBy === 'PO' ? this.state.ReferralNo : '',
			PaymentData: [],
			PaymentDataLoaded: false,
			IsCCProviderExists: (this.state.ProviderNetTerms === 'CC-Amex' || this.state.ProviderNetTerms === 'CC-Visa') ? true : false
		});
	}
	handleStartDateChange(event) {
		this.setState({ StartDate: event });
	}
	handleEndDateChange(event) {
		this.setState({ EndDate: event });
	}
	handleReferralNoChange(event) {
		if (this.ValidatePO(event.target.value)) {
			this.setState({ ReferralNo: event.target.value });
		}
		else {
			return false;
		}
	}
	handleClearClick(event) {
		this.setState({
			SearchBy: 'DOS', StartDate: null, EndDate: null, ReferralNo: '', PaymentData: [], PaymentDataLoaded: false,
			IsCCProviderExists: (this.state.ProviderNetTerms === 'CC-Amex' || this.state.ProviderNetTerms === 'CC-Visa') ? true : false
		});
	}
	handleSearchClick(event) {
		let startDate = this.state.StartDate;
		let endDate = this.state.EndDate;
		let referralNo = this.state.ReferralNo.trim();
		let errorMsg = '';
		if (this.state.SearchBy === 'DOS') {
			if (startDate == null)
				errorMsg = 'Please select start date';
			else if (endDate == null)
				errorMsg = 'Please select end date';
			else if (new Date(startDate) > new Date(endDate))
				errorMsg = 'Please verify start date and end date. Start date should be less than end date';
		}
		else if (this.state.SearchBy === 'PO' && referralNo === '') {
			errorMsg = 'Please enter referral number';
		}

		var toast = document.querySelector('.searchError');
		if (toast != null)
			iZitoast.hide({ transitionOut: 'fadeOutUp' }, toast);

		if (errorMsg.trim() !== '') {
			iZitoast.error({ class: 'searchError', message: errorMsg.trim() });
			return false;
		}
		else {
			startDate = new Date(startDate);
			endDate = new Date(endDate);
		}

		this.setState({
			IsDisplayLoader: true, PaymentData: [], PaymentDataLoaded: true,
			IsCCProviderExists: (this.state.ProviderNetTerms === 'CC-Amex' || this.state.ProviderNetTerms === 'CC-Visa') ? true : false
		});
		axios.get(Config.API_URL + '/PaymentStatus/GetPaymentStatusData', {
			params: {
				startDate: startDate === null ? null : ((startDate.getMonth() + 1) + '-' + startDate.getDate() + '-' + startDate.getFullYear()),//new Date(startDate).toLocaleDateString(),
				endDate: endDate === null ? null : ((endDate.getMonth() + 1) + '-' + endDate.getDate() + '-' + endDate.getFullYear()),//new Date(endDate).toLocaleDateString(),
				referralNo: referralNo === "" ? 0 : referralNo
			}
		})
			.then(res => {
				this.setState({
					PaymentData: res.data.paymentStatusData,
					IsDisplayLoader: false,
					ProviderID: res.data.providerID,
					ProviderName: res.data.providerName,
					IsCCProviderExists: this.state.IsCCProviderExists ? this.state.IsCCProviderExists : res.data.isCCProviderExists
				}, () => this.setPageData(this.state.PaymentData))

			})
	}
	handleQueryClick(event) {
		this.setState({
			BillingQuery: {
				PO: event,
				Subject: event + ' Provider ID ' + this.state.ProviderID,
				Message: ''
			}
		});
	}
	handleBQueryCancelClick(event) {
		this.setState({
			BillingQuery: {
				PO: '',
				Subject: '',
				Message: '',
			}
		});
	}
	handleBQueryMessageChange(event) {
		this.setState({
			BillingQuery: {
				...this.state.BillingQuery,
				Message: event.target.value
			}
		});
	}
	handleBQuerySubjectChange(event) {
		this.setState({
			BillingQuery: {
				...this.state.BillingQuery,
				Subject: event.target.value
			}
		});
	}
	handleBQuerySendClick(event) {
		let PO = this.state.BillingQuery.PO;
		let message = this.state.BillingQuery.Message.trim();
		let subject = this.state.BillingQuery.Subject.trim();
		let providerID = this.state.ProviderID;
		let providerName = this.state.ProviderName.trim();
		let errorMsg = '';

		if (subject === '')
			errorMsg = "Please add subject to send billing query";
		else if (message === '')
			errorMsg = "Please add message to send billing query";

		var toast = document.querySelector('.billingQError');
		if (toast != null)
			iZitoast.hide({ transitionOut: 'fadeOutUp' }, toast);

		if (errorMsg.trim() !== '') {
			iZitoast.error({ class: 'billingQError', message: errorMsg });
			return false;
		}

		const state = store.getState();
		const provMailID = state.login.UserName;

		axios.get(Config.API_URL + '/PaymentStatus/SendEmailForBillingQuery', {
			params: {
				referralNo: PO,
				message: message,
				subject: subject,
				providerID: providerID,
				providerName: providerName,
				providerMailId: provMailID
			}
		})
			.then(res => {
				// iZitoast.destroy();
				iZitoast.success({ message: "Billing query sent to procare successfully" });
				this.setState({
					BillingQuery: {
						PO: '',
						Subject: '',
						Message: '',
					}
				}, () => { document.getElementById("btnBillingQueryCancel").click(); });
			})
	}
	handleEOPReasonClick(event) {
		this.setState({ EOPDescription: event });
	}
	handleEOPCancelClick(event) {
		this.setState({ EOPDescription: '' });
	}

	ValidatePO(PO) {
		let reg = /^[0-9]*$/;
		if (!reg.test(PO)) {
			return false;
		}
		else {
			return true;
		}
	}

	render() {
		return (
			<div>
				<main className="main-content">
					<div className="container-fluid">
						{Config.AlertMessage &&
							<div className="row">
								<div className="col-xl-12 col-lg-12">
									<div className="alert alert-warning">
										<strong>***{Config.AlertMessage}</strong>
									</div>
								</div>
							</div>}
						<div className="row">
							<div className="col-xl-7 col-lg-6 pb-3">
								<div className="title">
									<h1>Payment Status</h1>
								</div>
								<span className="strong fs-18">Your Current Payment Terms: </span><span className="fs-18">{this.state.ProviderNetTerms}</span>
							</div>
							<div className="col-xl-5 col-lg-6">
								<div className="alert alert-danger">
									<strong>***Please allow up to 72 hours to reflect any recently submitted invoices</strong>
									<p className="pt-2 pb-0"><strong>***Payment Information prior to Date of Service 01/21/2020 will not be visible in this portal</strong></p>
									{this.state.IsCCProviderExists && <p className="pt-2 pb-0"><strong>***Credit Card payments will not be reflected below</strong></p>}
								</div>
								{/* {this.state.IsCCProviderExists && <h2 className="text-red strong">***Credit Card payments will not be reflected below***</h2>} */}
							</div>
							
						</div>

						<div className="row mb-1">
							<div className="col-12">
								<div className="form-group">
									<div className="custom-control custom-radio custom-control-inline">
										<input type="radio" id="rbDateOfService" name="searchBy" className="custom-control-input" onChange={this.handleSearchByChange}
											checked={this.state.SearchBy === 'DOS' ? true : false} />
										<label className="custom-control-label" htmlFor="rbDateOfService">Date of Service</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline">
										<input type="radio" id="rbReferralNo" name="searchBy" className="custom-control-input" onChange={this.handleSearchByChange}
											checked={this.state.SearchBy === 'PO' ? true : false} />
										<label className="custom-control-label" htmlFor="rbReferralNo">PO#</label>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-6 col-lg-3" style={{ display: this.state.SearchBy === 'DOS' ? 'block' : 'none' }}>
								<div className="form-group">
									<div className="custom-react-datepicker">
										<DatePicker
											selected={this.state.StartDate}
											onChange={this.handleStartDateChange}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											className="form-control"
											placeholderText="Start Date"
											onKeyDown={e => e.preventDefault()}
											popperPlacement="bottom-end"
											popperModifiers={{
												flip: {
													enabled: false
												},
												preventOverflow: {
													enabled: true,
													escapeWithReference: false
												}
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-6 col-lg-3" style={{ display: this.state.SearchBy === 'DOS' ? 'block' : 'none' }}>
								<div className="form-group">
									<div className="custom-react-datepicker">
										<DatePicker
											selected={this.state.EndDate}
											onChange={this.handleEndDateChange}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
											className="form-control"
											placeholderText="End Date"
											onKeyDown={e => e.preventDefault()}
											popperPlacement="bottom-end"
											popperModifiers={{
												flip: {
													enabled: false
												},
												preventOverflow: {
													enabled: true,
													escapeWithReference: false
												}
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-sm-6 col-md-6 col-lg-2" style={{ display: this.state.SearchBy === 'PO' ? 'block' : 'none' }}>
								<div className="form-group">
									<input type="text" id="txtReferralNo" maxLength="9" className="form-control" placeholder="Referral No" onChange={this.handleReferralNoChange}
										value={this.state.ReferralNo} />
								</div>
							</div>
							<div className=" col-md-6 col-lg-4">
								<div className="form-group">
									<button type="submit" id="btnSearch" className="btn btn-primary mr-2" onClick={this.handleSearchClick}><span>Search</span></button>
									<button type="submit" id="btnClear" className="btn btn-secondary" onClick={this.handleClearClick}><span>Clear</span></button>
								</div>
							</div>
						</div>


						{/* <!-- Grid Table =====================================--> */}
						{this.state.PaymentDataLoaded &&
							<div>
								<div className="table-responsive grid-table">
									<table className="table table-hover table-border">
										<thead>
											<tr>
												<th onClick={() => this.sortData('appointmentNumber', 'number')} style={{ cursor: "pointer" }}>
													PO#
									{this.state.currentSortCol == "appointmentNumber" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "appointmentNumber" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('dateOfService', 'date')} style={{ cursor: "pointer" }}>
													Date Of Service
									{this.state.currentSortCol == "dateOfService" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "dateOfService" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('claimant','string')} style={{ cursor: "pointer" }}>
													Injured Worker
									{this.state.currentSortCol == "claimant" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "claimant" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('amountInvoiced', 'number')} style={{ cursor: "pointer" }}>
													Amount Invoiced
									{this.state.currentSortCol == "amountInvoiced" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "amountInvoiced" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('invoiceDate', 'date')} style={{ cursor: "pointer" }}>
													Date Invoiced
									{this.state.currentSortCol == "invoiceDate" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "invoiceDate" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('amountPaid', 'number')} style={{ cursor: "pointer" }}>
													Amount Paid
									{this.state.currentSortCol == "amountPaid" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "amountPaid" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('eopReason', 'string')} style={{ cursor: "pointer" }}>
													Adjustment
									{this.state.currentSortCol == "eopReason" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "eopReason" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('dueDate', 'date')} style={{ cursor: "pointer" }}>
													Due Date
									{this.state.currentSortCol == "dueDate" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "dueDate" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('checkNo', 'string')} style={{ cursor: "pointer" }}>
													Check/ACH #
									{this.state.currentSortCol == "checkNo" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "checkNo" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th onClick={() => this.sortData('paymentIssued', 'date')} style={{ cursor: "pointer" }}>
													Payment Issued
									{this.state.currentSortCol == "paymentIssued" && this.state.sortOrder == 'DESC' && <span>&nbsp;<img src="images/rectangle-down.svg" width="11px" height="14px" alt="" /></span>}
													{this.state.currentSortCol == "paymentIssued" && this.state.sortOrder == 'ASC' && <span>&nbsp;<img src="images/rectangle-up.svg" width="11px" height="14px" alt="" /></span>}
												</th>
												<th align="center" className="text-center">Billing Questions</th>
											</tr>
										</thead>
										<tbody>
											{this.state.PaymentData.length > 0 && this.state.currentPageData.map((item, key) => {
												return (
													<tr key={key}>
														<td>{item.appointmentNumber}</td>
														<td>{item.dateOfService}</td>
														<td>{item.claimant}</td>
														<td>${item.amountInvoiced}</td>
														<td>{item.invoiceDate}</td>
														<td>{(item.amountPaid < 0 && '-$' + (item.amountPaid * -1).toFixed(2)) || '$' + item.amountPaid}</td>
														<td className="profile-link">
															{(item.eopReason === 'Yes' &&
																<a href="#" data-toggle="modal" data-target="#eop-reasons" onClick={() => this.handleEOPReasonClick(item.eopDescription)}>
																	{item.eopReason}
																</a>)
																|| item.eopReason}
														</td>
														<td>{item.dueDate}</td>
														<td>{item.checkNo}</td>
														<td>{item.paymentIssued}</td>
														<td align="center" className="actions">
															<a href="#" title="Query" data-toggle="modal" data-target="#billing-query" onClick={() => this.handleQueryClick(item.appointmentNumber)}>
																<img src="images/info.svg" alt="Query" />
															</a>
														</td>
													</tr>
												);
											})}
											{!this.state.IsDisplayLoader && this.state.PaymentData.length <= 0 &&
												<tr>
													<td colSpan="11">
														No records found
													</td>
												</tr>

											}
										</tbody>
									</table>
									{this.state.IsDisplayLoader && <InlineLoader></InlineLoader>}
								</div>
								{this.state.PaymentData.length > 0 && this.renderPagination()}
							</div>
						}
					</div>
				</main>
				{/* // <!-- Billing Query Modal ===================================== --> */}
				<div className="modal fade" id="billing-query" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog  modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="modal-title">BILLING QUESTIONS?:</h2>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleBQueryCancelClick}>
									<img src="images/close.svg" alt="" />
								</button>
							</div>
							<div className="modal-body pb-0">
								<form>
									<div className="form-group">
										<label>Subject</label>
										<input type="text" className="form-control" id="txtBillingQuerySubject" placeholder="Subject"
											onChange={this.handleBQuerySubjectChange} value={this.state.BillingQuery.Subject} />
									</div>
									<div className="form-group">
										<label>Message</label>
										<textarea className="form-control" rows="3" id="txtBillingQueryMessage" onChange={this.handleBQueryMessageChange} value={this.state.BillingQuery.Message}></textarea>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary" id="btnBillingQuerySend" onClick={this.handleBQuerySendClick}><span>Submit</span></button>
								<button type="button" className="btn btn-secondary" data-dismiss="modal" id="btnBillingQueryCancel" onClick={this.handleBQueryCancelClick}><span>Cancel</span></button>
							</div>
						</div>
					</div>
				</div>

				{/* // <!-- EOP reasons Modal ===================================== --> */}
				<div className="modal fade" id="eop-reasons" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog  modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="modal-title">EOP reason(s)</h2>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleEOPCancelClick}>
									<img src="images/close.svg" alt="" />
								</button>
							</div>
							<div className="modal-body pb-0">
								<form>
									<div className="form-group">
										<ul className="list-unstyled large-text" dangerouslySetInnerHTML={{ __html: this.state.EOPDescription }}>
										</ul>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
