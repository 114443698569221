import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LogInAction } from '../../_redux/actions/sessionActions'
import { Redirect, Link } from 'react-router-dom'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import TnC from '../TnC/TnC'

class Login extends Component {
    constructor(props) {
        super(props);

        const localUserName = localStorage.getItem('UserName');
        const localRememberMe = localStorage.getItem('RememberMe');

        this.state = {
            UserName: !!localUserName && (localRememberMe ? localRememberMe == 'true' : false) ? localUserName : '',
            Password: '',
            // ProviderID: '',
            RememberMe: localRememberMe ? localRememberMe == 'true' : true,
            AcceptTnC: false,
            FormErrors: {
                UserName: '',
                Password: '',
                AcceptTnC: ''
                // ProviderID: ''
            },
            UserNameValid: true,
            PasswordValid: true,
            AcceptTnCValid: false,
            // ProviderIDValid: true,
            FormValid: false,
            ShowPassword: false,
            showLoginLoader: false
        }

        localStorage.setItem('RememberMe', this.state.RememberMe);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.AcceptTnCChange = this.AcceptTnCChange.bind(this);

        this.validateRequiredFieldsOnSubmite = this.validateRequiredFieldsOnSubmite.bind(this);
        this.RememberMeChange = this.RememberMeChange.bind(this);
        this.showPassword = this.showPassword.bind(this);
        this.hidePassword = this.hidePassword.bind(this);
        this.handleLogInCallBack = this.handleLogInCallBack.bind(this);
    }
    componentWillUnmount() {
        window.$('.modal').modal("hide");
    }

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => this.validateField(name, value));
        if (this.state.RememberMe && [e.target.name] == 'UserName') {
            localStorage.setItem('UserName', e.target.value.trim());
        }
    }
    onBlur(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.validateField(name, value);
    }
    RememberMeChange() {
        this.setState({ RememberMe: !this.state.RememberMe });
        if (!this.state.RememberMe) {
            localStorage.setItem('UserName', this.state.UserName);
            localStorage.setItem('RememberMe', !this.state.RememberMe);
        }
        else {
            localStorage.removeItem('UserName');
            localStorage.setItem('RememberMe', !this.state.RememberMe);
        }
    }
    AcceptTnCChange(){
        this.setState({ AcceptTnC: !this.state.AcceptTnC });
    }

    fireLogIn() {
        if (this.state.FormValid) {
            const LogInData = {
                "LoginID": this.state.UserName.trim(),
                "Passwords": this.state.Password.trim(),
                // "ProviderID": parseInt(this.state.ProviderID)
            }
            this.setState({ showLoginLoader: true }, () => {
                this.props.LogInAction(LogInData, this.handleLogInCallBack);
            });
        }
    }
    handleLogInCallBack() {
        this.setState({ showLoginLoader: false });
    }
    showPassword() {
        this.setState({ showPassword: true });
    }
    hidePassword() {
        this.setState({ showPassword: false });
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.FormErrors;
        let UserNameValid = this.state.UserNameValid;
        // let ProviderIDValid = this.state.ProviderIDValid;
        let PasswordValid = this.state.PasswordValid;

        switch (fieldName) {
            case 'UserName':
                if (!value || value.trim() == '') {
                    UserNameValid = false;
                    fieldValidationErrors.UserName = '*Please enter User ID';
                }
                else {
                    let pattern = this.state.UserName.match(/^(([\w.%+-]+)@([\w-]+\.)+([\w]{2,}))$/i);
                    UserNameValid = !!pattern ? pattern.length > 0 : false;
                    fieldValidationErrors.UserName = UserNameValid ? '' : '*Please enter valid User ID';
                }
                break;
            // case 'ProviderID':
            //     if (!value || value.trim() == '') {
            //         ProviderIDValid = false;
            //         fieldValidationErrors.ProviderID = '*Please enter Provider ID';
            //     }
            //     else {
            //         let pattern = this.state.ProviderID.match(/^([0-9]*)$/i);
            //         ProviderIDValid = !!pattern ? pattern.length > 0 : false;
            //         fieldValidationErrors.ProviderID = ProviderIDValid ? '' : '*Please enter valid Provider ID';
            //     }
            //     break;
            case 'Password':
                if (!value || value.trim() == '') {
                    PasswordValid = false;
                    fieldValidationErrors.Password = '*Please enter your Password';
                }
                else {
                    let passPattern1 = value.match(/[a-z]/);
                    let passPattern2 = value.match(/[A-Z]/);
                    let passPattern3 = value.match(/[0-9]/);
                    let passPattern4 = value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
                    let allValid = true;
                    fieldValidationErrors.Password = ''
                    if (!value || value.trim() == '') {
                        allValid = false;
                        PasswordValid = false;
                        fieldValidationErrors.Password = '*Please enter your Password';
                    }
                    else {
                        if (!!passPattern1 ? passPattern1.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 lowercase<NL>';
                        }
                        if (!!passPattern2 ? passPattern2.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 uppercase<NL>';
                        }
                        if (!!passPattern3 ? passPattern3.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 number<NL>';
                        }
                        if (!!passPattern4 ? passPattern4.length <= 0 : true) {
                            allValid = false;
                            PasswordValid = false;
                            fieldValidationErrors.Password += '*Please enter at least 1 special character<NL>';
                        }
                        if (value.length < 8) {
                            allValid = false;
                            PasswordValid = false
                            fieldValidationErrors.Password += '*Password must be a minimum of 8 characters<NL>';
                        }
                        if (allValid) {
                            PasswordValid = true;
                            fieldValidationErrors.Password = '';
                        }
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            FormErrors: fieldValidationErrors,
            UserNameValid: UserNameValid,
            PasswordValid: PasswordValid,
            // ProviderIDValid: ProviderIDValid
        });
    }
    validateRequiredFieldsOnSubmite(e) {
        e.preventDefault();
        let fieldValidationErrors = this.state.FormErrors;
        let UserNameValid = this.state.UserNameValid;
        let PasswordValid = this.state.PasswordValid;
        let AcceptTnCValid = this.state.AcceptTnCValid;
        // let ProviderIDValid = this.state.ProviderIDValid;

        if (!this.state.UserName || this.state.UserName.trim() == '') {
            UserNameValid = false;
            fieldValidationErrors.UserName = UserNameValid ? '' : '*Please enter User ID';
        }
        if (UserNameValid) {
            let pattern = this.state.UserName.match(/^(([\w.%+-]+)@([\w-]+\.)+([\w]{2,}))$/i);
            UserNameValid = !!pattern ? pattern.length > 0 : false;
            fieldValidationErrors.UserName = UserNameValid ? '' : '*Please enter valid User ID';
        }
        // if (!this.state.ProviderID || this.state.ProviderID.trim() == '') {
        //     ProviderIDValid = false;
        //     fieldValidationErrors.ProviderID = ProviderIDValid ? '' : '*Please enter Provider ID';
        // }
        // if (ProviderIDValid) {
        //     let pattern = this.state.ProviderID.match(/^([0-9]*)$/i);
        //     ProviderIDValid = !!pattern ? pattern.length > 0 : false;
        //     fieldValidationErrors.ProviderID = ProviderIDValid ? '' : '*Please enter valid Provider ID';
        // }

        let passPattern1 = this.state.Password.match(/[a-z]/);
        let passPattern2 = this.state.Password.match(/[A-Z]/);
        let passPattern3 = this.state.Password.match(/[0-9]/);
        let passPattern4 = this.state.Password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);
        let allValid = true;
        fieldValidationErrors.Password = ''
        if (!this.state.Password || this.state.Password.trim() == '') {
            allValid = false;
            PasswordValid = false;
            fieldValidationErrors.Password = '*Please enter your Password';
        }
        else {
            if (!!passPattern1 ? passPattern1.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 lowercase<NL>';
            }
            if (!!passPattern2 ? passPattern2.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 uppercase<NL>';
            }
            if (!!passPattern3 ? passPattern3.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 number<NL>';
            }
            if (!!passPattern4 ? passPattern4.length <= 0 : true) {
                allValid = false;
                PasswordValid = false;
                fieldValidationErrors.Password += '*Please enter at least 1 special character<NL>';
            }
            if (this.state.Password.length < 8) {
                allValid = false;
                PasswordValid = false
                fieldValidationErrors.Password += '*Password must be a minimum of 8 characters<NL>';
            }
            if (allValid) {
                PasswordValid = true;
                fieldValidationErrors.Password = '';
            }
        }

        if(!this.state.AcceptTnC){
            AcceptTnCValid = false;
            fieldValidationErrors.AcceptTnC = '*Please accept Terms & Conditions'
        }
        else{
            AcceptTnCValid = true;
            fieldValidationErrors.AcceptTnC = ''
        }
        this.setState({
            FormErrors: fieldValidationErrors,
            UserNameValid: UserNameValid,
            PasswordValid: PasswordValid,
            AcceptTnCValid: AcceptTnCValid
            // ProviderIDValid: ProviderIDValid
        }, this.validateForm);
    }
    validateForm() {
        this.setState({
            FormValid: this.state.UserNameValid
                && this.state.PasswordValid
                && this.state.AcceptTnCValid
            // && this.state.ProviderIDValid
        }, this.fireLogIn);
    }

    ShowTnc(){
        window.$('#TnC-Modal').modal('show');
    }

    render() {
        if (this.props.IsLoggedIn == "1")
            return <Redirect to="/MyProfile" />
        return (
            <div>
                
                <div className="wrapper login-page">
                    <div className="login-panel">
                        <div className="login-block">
                            <img src="images/logo.png" width="245px" alt="" />
                            <div className="card">
                                <span className="login-icon"><span><img src="images/login-icon.svg" alt="" /></span></span>
                                <h2>Welcome, Provider!</h2>
                                <form onSubmit={this.validateRequiredFieldsOnSubmite}>
                                    {/* <div className="form-group">
                                        {!this.state.ProviderIDValid && <div className="errorMsg">{this.state.FormErrors.ProviderID}</div>}
                                        <input
                                            type="text"
                                            className={!this.state.ProviderIDValid ? "form-control inValidaData" : "form-control"}
                                            placeholder="Provider ID"
                                            name="ProviderID"
                                            value={this.state.ProviderID}
                                            onChange={this.onChange}
                                            onBlur={this.onBlur}
                                            title="Please enter your Provider ID"
                                            autoFocus
                                            tabIndex={1}
                                        />
                                    </div> */}
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={!this.state.UserNameValid ? "form-control inValidaData" : "form-control"}
                                            placeholder="User ID"
                                            name="UserName"
                                            value={this.state.UserName}
                                            onChange={this.onChange}
                                            onBlur={this.onBlur}
                                            title="Please enter your User ID"
                                            tabIndex={1}
                                            autoFocus
                                        />
                                        {!this.state.UserNameValid && <div className="errorMsg">{this.state.FormErrors.UserName}</div>}
                                    </div>

                                    <div >
                                        <div className="form-group">
                                            <input
                                                type={!this.state.showPassword ? "password" : "text"}
                                                className={!this.state.PasswordValid ? "form-control inValidaData" : "form-control"}
                                                placeholder="Password"
                                                name="Password"
                                                value={this.state.Password}
                                                onChange={this.onChange}
                                                onBlur={this.onBlur}
                                                title="Please enter your Password"
                                                tabIndex={2}
                                            />
                                            <span onTouchStart={this.showPassword} onTouchEnd={this.hidePassword} onMouseDown={this.showPassword} onMouseUp={this.hidePassword} onMouseLeave={this.hidePassword} title="Show Password" className={!this.state.showPassword ? "password-show show" : "password-show show active"}></span>
                                            {!this.state.PasswordValid && <div className="errorMsg">{this.state.FormErrors.Password.split('<NL>').map((item, key) => { return (<div key={key}>{item}</div>) })}</div>}
                                        </div>
                                    </div>
                                    <div className="form-group text-left mb-2">
                                        <div className="clearfix pb-3">
                                            <div className="custom-control custom-checkbox float-left" onClick={this.RememberMeChange}>
                                                <input type="checkbox" className="custom-control-input" name="RememberMe" checked={this.state.RememberMe} onChange={this.RememberMeChange} />
                                                <label className="custom-control-label" htmlFor="customControlInline" title="It will save your User ID in your browser for future use">Remember me</label>
                                            </div>
                                            <a className="forgot float-right" href="#" title="Forgot password?" data-toggle="modal" data-target="#forget-password">Forgot password?</a>
                                        </div>
                                        <div className="custom-control custom-checkbox size-sm">
                                            <span onClick={this.AcceptTnCChange}>
                                            <input type="checkbox" tabIndex={3} className="custom-control-input" name="AcceptTnC" checked={this.state.AcceptTnC} onChange={this.AcceptTnCChange}/>
                                            <label className="custom-control-label label-sm" htmlFor="customControlInline" title="I have read and agree Terms &amp; Conditions">I have read and agree &nbsp;</label>
                                            </span>
                                            <label className="label-sm">
                                            <span className="primary-link" style={{cursor:"pointer"}} onClick={this.ShowTnc}>Terms &amp; Conditions</span>
                                            </label>
                                        </div>
                                        {!this.state.AcceptTnC && !this.state.AcceptTnCValid && <div className="errorMsg">{this.state.FormErrors.AcceptTnC}</div>}
                                    </div>
                                    <button tabIndex={4} type="submit" className={this.state.showLoginLoader ? "btn btn-primary w-100 loading" : "btn btn-primary w-100"} title="Log In"><span>Log in</span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="modal fade" id="forget-password" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <ForgotPassword></ForgotPassword>
                    </div>
                    <TnC />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    IsLoggedIn: state.login.IsLoggedIn
})
export default connect(mapStateToProps, { LogInAction })(Login)