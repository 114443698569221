import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios'
import Config from '../../Config'
import iZitoast from 'izitoast'
import store from '../../_redux/store';

export default class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbackMessage: '',
            subject: '',
            FormErrors: '',
            FeedBackValid: true,
            FormValid: false
        }
        this.onChange = this.onChange.bind(this);

        this.validateRequiredFieldsOnSubmite = this.validateRequiredFieldsOnSubmite.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
    }
    onChange = event => {
        let FieldName = event.target.name;
        this.setState({ [FieldName]: event.target.value }, () => {
            if (FieldName == 'feedbackMessage')
                this.validateField(this.state.feedbackMessage)
        });
    }
    submitFeedBack() {
        if (this.state.FormValid) {
            const state = store.getState();
            const Token = state.login.Token;
            const FeedbackData = {
                "Feedback": this.state.feedbackMessage,
                "Subject": this.state.subject,
                "Token": Token
            }
            axios.post(Config.API_URL + '/FeedBack/SaveFeedBack', FeedbackData)
                .then(res => {
                    if (res.data.resultType === "success") {
                        iZitoast.destroy();
                        iZitoast.success({ message: res.data.resultMessage, timeout: 7000 });
                    }
                    else if (res.data.resultType === "error") {
                        iZitoast.destroy();
                        iZitoast.error({ message: res.data.resultMessage, timeout: 7000 });
                    }
                })
                .catch(
                    err => {

                    })
            this.setState({
                feedbackMessage: '',
                subject: ''
            })
        }
    }
    clearMessage = event => {
        this.setState({
            feedbackMessage: '',
            subject: '',
            FeedBackValid: true
        })
    }
    validateField(value) {
        let validationErrors = this.state.FormErrors;
        let FeedBackValid = this.state.FeedBackValid;

        if (!value || value.trim() == '') {
            FeedBackValid = false;
            validationErrors = '*Please enter feedback message';
        }
        else {
            FeedBackValid = true;
            validationErrors = '';
        }

        this.setState({
            FormErrors: validationErrors,
            FeedBackValid: FeedBackValid
        });
    }
    validateRequiredFieldsOnSubmite(e) {
        e.preventDefault();
        let validationErrors = this.state.FormErrors;
        let FeedBackValid = this.state.FeedBackValid;

        if (!this.state.feedbackMessage || this.state.feedbackMessage.trim() == '') {
            FeedBackValid = false;
            validationErrors = '*Please enter feedback message';
        }
        else {
            FeedBackValid = true;
            validationErrors = '';
        }
        this.setState({
            FormErrors: validationErrors,
            FeedBackValid: FeedBackValid
        }, this.validateForm);
    }
    validateForm() {
        this.setState({ FormValid: this.state.FeedBackValid }, this.submitFeedBack);
    }
    render() {
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="title">
                        <h1>Feedback</h1>
                    </div>
                    <form onSubmit={this.validateRequiredFieldsOnSubmite}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Subject (Optional)"
                                        name="subject"
                                        value={this.state.subject}
                                        onChange={this.onChange}
                                        onBlur={this.onBlur}
                                        title="Subject"
                                        maxLength="200"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea placeholder="Message" className={!this.state.FeedBackValid ? "form-control inValidaData" : "form-control"} rows="5" name="feedbackMessage" value={this.state.feedbackMessage} onChange={this.onChange} ></textarea>
                                    {!this.state.FeedBackValid && <div className="errorMsg">{this.state.FormErrors}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit"><span>Submit</span></button>
                                    <button type="button" className="btn btn-secondary ml-2" onClick={this.clearMessage}><span>Cancel</span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        )
    }
}